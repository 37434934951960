import React from "react";
import FAQAccordion from "../component/FAQAccordion";
import EllipseAnimation from "./EllipsisAnimation";

function Investors() {
  const InvestorData = [
    { name: "Service" },
    { name: "Healthcare" },
    { name: "Bio - Technology" },
    { name: "Real Estate" },
    { name: "Finance" },
    { name: "Education" },
    { name: "AI and Technology" },
    { name: "Automobile" },
    { name: "Sustainability" },
  ];
  const investments = [
    {
      src: "/assets/images/Access_to_top_deals.png",
      heading: "Access Top Deals",
      content:
        "Partner with aligned investors and fund managers, gaining access to high-potential startup opportunities and premium deal flow.",
    },
    {
      src: "/assets/images/StreamlinedInvestments.png",
      heading: "Streamlined Investing",
      content:
        "BizDateUp takes care of compliance, KYC verification, and taxes, so you can focus on making impactful investments.",
    },
    {
      src: "/assets/images/DiversifyInvestment.png",
      heading: "Diversify Your Portfolio",
      content:
        "Invest across a wide range of industries and syndicates, joining forces with like-minded investors to grow your startup portfolio.",
    },
  ];
  const items = [
    {
      src: "/assets/images/battre.png",
      alt: "web design icon",
      tooltip: "Unrealized Returns",
      amount: "+92%^",
    },
    { src: "", alt: "", tooltip: "" },
    {
      src: "/assets/images/immersive.png",
      alt: "game design icon",
      tooltip: "Unrealized Returns",
      amount: " +52%^",
    },
    {
      src: "/assets/images/infinityX.png",
      alt: "game dev icon",
      tooltip: "Unrealized Returns",
      amount: " +488%^",
    },
    {
      src: "/assets/images/myints.png",
      alt: "ui-ux icon",
      tooltip: "Unrealized Returns",
      amount: "+175%^",
    },
    { src: "", alt: "", tooltip: "", amount: "" },
    {
      src: "/assets/images/pdrl.png",
      alt: "app icon",
      tooltip: "Unrealized Returns",
      amount: "+3.6%^",
    },
    { src: "", alt: "", tooltip: "", amount: "" },
    {
      src: "/assets/images/square.png",
      alt: "blockchain icon",
      tooltip: "Unrealized Returns",
      amount: "+275%^",
    },
    { src: "", alt: "", tooltip: "", amount: "" },
    {
      src: "/assets/images/Stylework.png",
      alt: "ar-vr icon",
      tooltip: "Unrealized Returns",
      amount: "+167%^",
    },
  ];

  return (
    <div className="main-layout">
      <section className="fund-section investor-labels">
        <div className="container">
          <div className="investor-scroll-container">
            <div className="investor-scroll scroll-left">
              {InvestorData.map((data, index) => (
                <div className="investor-data" key={`scroll1-${index}`}>
                  {data.name}
                </div>
              ))}
              {InvestorData.map((data, index) => (
                <div
                  className="investor-data"
                  key={`scroll1-duplicate-${index}`}
                >
                  {data.name}
                </div>
              ))}
              {InvestorData.map((data, index) => (
                <div
                  className="investor-data"
                  key={`scroll1-duplicate-${index}`}
                >
                  {data.name}
                </div>
              ))}
              {InvestorData.map((data, index) => (
                <div
                  className="investor-data"
                  key={`scroll1-duplicate-${index}`}
                >
                  {data.name}
                </div>
              ))}
            </div>
          </div>
          <h4 className="head">Electric Vehicle</h4>
          <div className="row">
            <div className="col-md-6 col-lg-4">
              <div className="card">
                <img
                  src="/assets/images/cards/battre.png"
                  alt="img"
                  className="img-fluid card-img card-img1"
                />
                {/* <img src="/assets/images/HiTech.png" alt="img" className="h-25 w-25 my-3" />
                                <p className="subcontent">Advanced AI-driven cybersecurity solutions to protect businesses from evolving threats.</p>
                                <div className="d-flex flex-wrap">
                                    <label>EV</label>
                                    <label>B2B</label>
                                    <label>Technology</label>
                                    <label>Automobile</label>
                                </div>
                                <p className="fund-details mt-3 mb-0">₹ 1.3 Cr  <span>Raised from 90 Investors</span></p>
                                <p className="fund-details mt-2 mb-0">₹ 9 Cr <span>Valuation</span></p> */}
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="card">
                <img
                  src="/assets/images/cards/InfinityX.png"
                  alt="img"
                  className="img-fluid card-img"
                />
                {/* <img src="/assets/images/Eco-Wave.png" alt="img" className="h-50 w-50 my-3" />
                                <p className="subcontent">Revolutionizing sustainable energy solutions with innovative, eco-friendly technologies.</p>
                                <div className="d-flex flex-wrap">
                                    <label>EV</label>
                                    <label>B2B</label>
                                    <label>Technology</label>
                                    <label>Automobile</label>
                                </div> */}
              </div>
            </div>
            <div className="col-md-6 col-lg-4 mx-auto">
              <div className="card">
                <img
                  src="/assets/images/cards/Immersive.png"
                  alt="img"
                  className="img-fluid card-img"
                />
                {/* <img src="/assets/images/Healthify.png" alt="img" className="h-25 w-25 my-3" />
                                <p className="subcontent">Revolutionizing sustainable energy solutions with innovative, eco-friendly technologies.</p>
                                <div className="d-flex flex-wrap">
                                    <label>EV</label>
                                    <label>B2B</label>
                                    <label>Technology</label>
                                    <label>Automobile</label>
                                </div> */}
              </div>
            </div>
          </div>
          <h4 className="head">Insurance Tech</h4>
          <div className="row">
            {/* <div className="col-md-6 col-lg-4">
                            <div className="card">
                                <img src="/assets/images/cards/InfinityX.png" alt="img" className="img-fluid card-img card-img1" />
                                <img src="/assets/images/HiTech.png" alt="img" className="h-25 w-25 my-3" />
                                <p className="subcontent">Advanced AI-driven cybersecurity solutions to protect businesses from evolving threats.</p>
                                <div className="d-flex flex-wrap">
                                    <label>EV</label>
                                    <label>B2B</label>
                                    <label>Technology</label>
                                    <label>Automobile</label>
                                </div>
                                <p className="fund-details mt-3 mb-0">₹ 1.3 Cr  <span>Raised from 90 Investors</span></p>
                                <p className="fund-details mt-2 mb-0">₹ 9 Cr <span>Valuation</span></p>
                            </div>
                        </div> */}
            <div className="col-md-6 col-lg-4">
              <div className="card">
                <img
                  src="/assets/images/cards/SQUARE.png"
                  alt="img"
                  className="img-fluid card-img"
                />
                {/* <img src="/assets/images/Eco-Wave.png" alt="img" className="h-50 w-50 my-3" />
                                <p className="subcontent">Revolutionizing sustainable energy solutions with innovative, eco-friendly technologies.</p>
                                <div className="d-flex flex-wrap">
                                    <label>EV</label>
                                    <label>B2B</label>
                                    <label>Technology</label>
                                    <label>Automobile</label>
                                </div> */}
              </div>
            </div>
          </div>
          {/* <h4 className="head">Food Tech</h4>
                    <div className="row">
                        <div className="col-md-6 col-lg-4">
                            <div className="card">
                                <img src="/assets/images/cards/myints.png" alt="img" className="img-fluid card-img" />
                                <img src="/assets/images/Eco-Wave.png" alt="img" className="h-50 w-50 my-3" />
                                <p className="subcontent">Revolutionizing sustainable energy solutions with innovative, eco-friendly technologies.</p>
                                <div className="d-flex flex-wrap">
                                    <label>EV</label>
                                    <label>B2B</label>
                                    <label>Technology</label>
                                    <label>Automobile</label>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4">
                            <div className="card">
                                <img src="/assets/images/cards/PDRL.png" alt="img" className="img-fluid card-img" />
                                <img src="/assets/images/Eco-Wave.png" alt="img" className="h-50 w-50 my-3" />
                                <p className="subcontent">Revolutionizing sustainable energy solutions with innovative, eco-friendly technologies.</p>
                                <div className="d-flex flex-wrap">
                                    <label>EV</label>
                                    <label>B2B</label>
                                    <label>Technology</label>
                                    <label>Automobile</label>
                                </div>
                            </div>
                        </div>
                    </div>  */}
        </div>
      </section>
      <section className="local-favourite-section">
        <div className="container">
          <div className="text-center">
            <h4 className="heading">
              <span>Invest in Founders </span> Driving the Future{" "}
            </h4>
          </div>
          <EllipseAnimation items={items} />
        </div>
      </section>
      <section className="investment-section">
        <div className="container">
          <div className="text-center">
            <h4 className="heading">New to Investments</h4>
            <p className="content mt-3">Here’s how it works on BizDateUp</p>
          </div>
          {investments.map((data, index) => (
            <div className="row" key={index}>
              <div className="col-lg-6">
                <div className="img d-flex flex-row align-items-center">
                  <img src={data.src} className="img-fluid" alt="img" />
                </div>
              </div>
              <div className="col-lg-6 d-flex flex-column justify-content-center">
                <h5 className="subheading">{data.heading}</h5>
                <p className="subcontent mt-2">{data.content}</p>
              </div>
            </div>
          ))}
        </div>
      </section>
      {/* <section className="faq-section">
                <div className="container">
                    <div className="text-center">
                        <h4 className="heading">Frequently asked questions</h4>
                        <p className="content mt-3">Everything you need to know about the product and billing.</p>
                    </div>
                    <FAQAccordion />
                </div>
            </section> */}
    </div>
  );
}

export default Investors;
