import { Link } from "react-router-dom";

function Footer() {
  return (
    <div className="footer">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <Link to="/">
              <img src="/assets/images/BizDateUp-logo.png" alt="header_logo" />
            </Link>
            <div className="mt-4">
              <h6 className="heading">Address:</h6>
              <p className="content">
                Sukh Sagar, CHS Limited, 605, N S Patkar Marg,<br></br> Charni
                Road, Sea Face, Gamdevi,<br></br> Mumbai, Maharashtra 400007
              </p>
            </div>
            <div className="mt-4">
              <h6 className="heading">Contact:</h6>
              <a
                href="tel://+917738583751"
                onclick="window.open('thispage.htm','_self');"
                className="black-link"
              >
                +91 77385 83751
              </a>
              {/* <p className="content">contact@helby.com</p> */}
              <h6 className="content mt-2">
                <a href="mailto:support@bizdateup.com" className="black-link ">
                  support@bizdateup.com
                </a>
              </h6>
            </div>
            <div className="d-flex flex-wrap mt-4">
              <a
                href="https://www.facebook.com/bizdateupindia/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="/assets/images/facebook.png"
                  className="icon"
                  alt="icon"
                />
              </a>
              <a
                href="https://www.instagram.com/bizdateup/?hl=en"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="/assets/images/instagram.png"
                  className="icon"
                  alt="Instagram"
                />
              </a>
              <a
                href="https://in.linkedin.com/company/bizdateup"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="/assets/images/linkdln.png"
                  className="icon"
                  alt="LinkedIn"
                />
              </a>
              <a
                href="https://www.youtube.com/channel/UCAwc4LgejYjGbeZu04wyZFA"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="/assets/images/youtube.png"
                  className="icon"
                  alt="YouTube"
                />
              </a>
            </div>
          </div>
          <div className="col-md-6 mt-5 mt-md-0">
            <div className="d-flex flex-row justify-content-md-end justify-content-start">
              <ul className="navbar-nav ms-md-5">
                <li className="nav-item">
                  <Link className="nav-link" to="/contact">
                    Contact Us
                  </Link>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="https://investor.bizdateup.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Sign In
                  </a>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    to="https://investor.bizdateup.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Register Now
                  </Link>
                </li>
              </ul>
              <ul className="navbar-nav ms-5">
                {/* <li className="nav-item">
                                    <Link className="nav-link" to="/">Resources</Link>
                                </li> */}
                <li className="nav-item">
                  <Link className="nav-link" to="/explore">
                    Explore
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/blog">
                    Blog
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/News">
                    News
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/Faq">
                    FAQ'S
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div>
            <ul className="d-flex list-unstyled gap-3 m-0 p-0">
              <li className="nav-item">
                <Link className="nav-link link" to="/importantDisclosures">
                  Important Disclosures
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link link" to="/termsOfService">
                  Terms of Service
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link link" to="/privacypolicy">
                  Privacy Policy
                </Link>
              </li>
            </ul>
          </div>

          <div className="copyright-text">
            {" "}
            © {new Date().getFullYear()} BizDateUp. All rights reserved.
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
