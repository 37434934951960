import React from 'react';
import { Link } from 'react-router-dom';

function Header() {
    return (
        <div className="container">
            <nav className="navbar navbar-expand-xl navbar-light bg-light">
                <div className="navbar-responsive">
                    <Link to="/" className="navbar-brand"> 
                        <img src="/assets/images/logo.svg" alt="header_logo" /> 
                    </Link>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                        <img src="/assets/images/menu.svg" alt="icon" className="h-75 w-75" />
                    </button>
                </div>
                <div className="collapse navbar-collapse" id="navbarNavDropdown">
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <Link className="nav-link" to="/explore">Explore</Link>
                        </li                                         >
                        <li className="nav-item">
                            <Link className="nav-link" to="/Faq">FAQ's</Link>
                        </li>
                        {/* <li className="nav-item">
                            <Link className="nav-link" to="/blogs">Blog</Link>
                        </li> */}
                        {/* <li className="nav-item">
                            <Link className="nav-link" to="/"><img src="/assets/images/search-icon.png" className="me-2" alt="icon" />Search</Link>
                        </li> */}
                    </ul>
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <Link className="nav-link" to="/blog">Blog</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/News">News</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/contact">Contact us</Link>
                        </li>
                        <li className="nav-item">
                        <button className="nav-button1" onClick={() => window.open("https://investor.bizdateup.com/", "_blank")}>
                                    Sign in
                                </button>
                            {/* <Link className="nav-link" to="/UnderDevelopment">Sign in</Link> */}
                        </li>
                        <li className="nav-item button-margin-mobile">
                            <button className="nav-button" onClick={() => window.open("https://investor.bizdateup.com/", "_blank")}>
                                Get Started <img src="/assets/images/right-arrow.png" alt="icon" />
                            </button>
                        </li>
                    </ul>
                </div>
            </nav>
        </div>
    )
}

export default Header;