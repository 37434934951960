import React, { useRef, useEffect, useState } from "react";
import Header from "../component/Header";
import Footer from "../component/Footer";
import AutoScroll from "./startAutoScroll";

function Home() {
  const InvestorData = [
    { name: "AI-Powered Healthcare Solutions" },
    { name: "GreenTech for a Sustainable Future" },
    { name: "Fintech for Financial Inclusion" },
    { name: "Blockchain for Secure Transactions" },
    { name: "Smart Home Automation" },
    { name: "Service" },
  ];
  const InvestorData2 = [
    { name: "Healthcare" },
    { name: "Bio - Technology" },
    { name: "Real Estate " },
    { name: "Finance" },
    { name: "Education" },
    { name: "AI and Technology" },
    { name: "Automobile" },
  ];

  const whyBizDateUp = [
    {
      src: "assets/images/why-bizdate1.svg",
      heading: "Invest and Own a Piece of the Future",
      content:
        "Unlike crowdfunding platforms where you pledge for rewards, on BizDateUp, your investment translates to real equity in high-potential startups.",
    },
    {
      src: "assets/images/why-bizdate2.svg",
      heading: "Grow Your Wealth with Time",
      content:
        "Build a diverse investment portfolio by tapping into emerging startups across sectors. Leverage your expertise, insights, and the collective wisdom of fellow investors for long-term growth.",
    },
    {
      src: "assets/images/why-bizdate3.svg",
      heading: "Stay Connected and Involved",
      content:
        "As an investor on BizDateUp, you'll receive exclusive updates, attend events, watch founder videos, and directly engage with startup teams to help shape their journey to success.",
    },
  ];
  const investments = [
    {
      src: "/assets/images/Access_to_top_deals.png",
      heading: "Access Top Deals",
      content:
        "Partner with aligned investors and fund managers, gaining access to high-potential startup opportunities and premium deal flow.",
    },
    {
      src: "/assets/images/StreamlinedInvestments.png",
      heading: "Streamlined Investing",
      content:
        "BizDateUp takes care of compliance, KYC verification, and taxes, so you can focus on making impactful investments.",
    },
    {
      src: "/assets/images/DiversifyInvestment.png",
      heading: "Diversify Your Portfolio",
      content:
        "Invest across a wide range of industries and syndicates, joining forces with like-minded investors to grow your startup portfolio.",
    },
  ];
  const carouselItems = [
    {
      id: 1,
      image: "/assets/images/zomato.svg",
      logo: "/assets/images/zomato-icon.svg",
      heading: "Story of ₹1crore Becoming  ₹2000crore Fortune",
      investmentLabel: "Investment You Made",
      calculationFactor: 2000,
      fortuneLabel: "Investment Estimated fortune",
      buttonIcon: "",
      buttonLabel: "Check Now",
      color: "#E33845",
    },
    {
      id: 2,
      image: "/assets/images/ola.svg",
      logo: "/assets/images/ola-icon.svg",
      heading: "Story of 1cr Bringing 25x Returns in 3 Years",
      investmentLabel: "Investment You Made",
      calculationFactor: 25,
      fortuneLabel: "Investment Estimated fortune",
      buttonIcon: "",
      buttonLabel: "Check Now",
      color: "#A2AA00",
    },
    {
      id: 3,
      image: "/assets/images/flipkart.svg",
      logo: "/assets/images/flipkart-icon.svg",
      heading: "Story of 10 Lakh Becoming 20M $’s Fortune",
      investmentLabel: "Investment You Made",
      calculationFactor: 1600,
      fortuneLabel: "Investment Estimated fortune",
      buttonIcon: "",
      buttonLabel: "Check Now",
      color: "#007CD8",
    },
    {
      id: 4,
      image: "/assets/images/nykaa.svg",
      logo: "/assets/images/nykaa-icon.svg",
      heading: "Story of 4M $ Becoming 640M $’s Fortune",
      investmentLabel: "Investment You Made",
      calculationFactor: 160,
      fortuneLabel: "Investment Estimated fortune",
      buttonIcon: "",
      buttonLabel: "Check Now",
      color: "#FB3A7B",
    },
    {
      id: 5,
      image: "/assets/images/next-frame.svg",
      logo: "/assets/images/bizdateup-icon.png",
      heading:
        "Discover the journey from ideas to impact join BizDateUp today!",
      investmentLabel: "",
      fortuneLabel: "",
      buttonIcon: "/assets/images/whatsapp-icon.svg",
      buttonLabel: "Talk to Our Expert",
      color: "#272858",
    },
  ];
  const scrollContainerRef = useRef(null);
  const scrollInterval = useRef(null);
  const clearInputs = () => {
    setInvestmentAmount(""); // Clear the investment input field
    setFortuneAmount(""); // Clear the fortune input field
  };

  const [investmentAmount, setInvestmentAmount] = useState("");
  const [fortuneAmount, setFortuneAmount] = useState("");

  const handleCalculate = (calculationFactor) => {
    const investment = parseFloat(investmentAmount); // Convert input to a number
    if (!isNaN(investment) && calculationFactor) {
      const result = investment * calculationFactor; // Use the provided calculation factor
      setFortuneAmount(result.toLocaleString()); // Format the result and update state
    } else {
      setFortuneAmount(""); // Reset if the input is invalid
    }
  };

  const startAutoScroll = () => {
    scrollInterval.current = setInterval(() => {
      if (scrollContainerRef.current) {
        const container = scrollContainerRef.current;
        container.scrollTop += 1;

        if (
          container.scrollTop >=
          container.scrollHeight - container.clientHeight
        ) {
          const cardsWrapper = container.querySelector(".cards-wrapper");
          const firstCard = cardsWrapper.querySelector(".hero-cards");

          cardsWrapper.appendChild(firstCard);

          container.scrollTop = container.scrollHeight - container.clientHeight;
        }
      }
    }, 20);
  };

  const stopAutoScroll = () => {
    if (scrollInterval.current) {
      clearInterval(scrollInterval.current);
    }
  };

  useEffect(() => {
    startAutoScroll();

    return () => stopAutoScroll();
  }, []);

  const Counter = ({ endValue, prefix = "", suffix = "" }) => {
    const [value, setValue] = useState(0);

    useEffect(() => {
      let startValue = 0;
      const duration = 2000; // 2 seconds
      const increment = endValue / (duration / 50);

      const timer = setInterval(() => {
        startValue += increment;
        if (startValue >= endValue) {
          setValue(endValue);
          clearInterval(timer);
        } else {
          setValue(Math.round(startValue));
        }
      }, 50);

      return () => clearInterval(timer);
    }, [endValue]);

    return (
      <h1 className="subheading">
        {prefix}
        {value.toLocaleString()}
        {suffix}
      </h1>
    );
  };
  return (
    <div className="main-layout">
      <Header />


      <section className="hero-section mt-0">
        <div className="container">
          <div className="row d-flex justify-content-between">
            <div className="col-xl-5">
              <div className="future-of-startups">
                <h1>
                  Powering the Future of <span>Startups and Investments</span>
                </h1>
                <p className="mt-3">
                  Whether you're an investor seeking exciting opportunities or a
                  startup looking for the right support, we bring everything you
                  need to make the right connections.
                </p>
                <div className="buttons">
                  <button
                    className="start-investing-btn"
                    onClick={() =>
                      window.open("https://investor.bizdateup.com/", "_blank")
                    }
                  >
                    Join BizDateUp
                    <img
                      src="/assets/images/right-arrow.png"
                      alt="icon"
                      className="ms-1"
                    />
                  </button>
                  <button
                    className="enroll-startup-btn mt-2 mt-sm-0"
                    onClick={() =>
                      window.open("https://investor.bizdateup.com/", "_blank")
                    }
                  >
                    Start Investing
                    <img
                      src="/assets/images/right-arrow-primary.png"
                      alt="icon"
                      className="ms-1"
                    />
                  </button>
                </div>
              </div>
            </div>
            <div className="col-xl-6 px-5 px-xl-0 ">
              <div className="auto-card-scroll hide-mobile">
                <AutoScroll />
              </div>
              <div
                id="carouselExampleIndicators3"
                className="carousel slide hide-desktop"
                data-bs-ride="carousel"
                data-bs-interval="1000"
              >
                <div className="carousel-inner">
                  <div className="carousel-item active">
                    <div className="row">
                      <div className="col-md-6 col-lg-4">
                        <div className="card border-none">
                          <img
                            src="/assets/images/cards/battre.png"
                            alt="img"
                            className="img-fluid card-img card-img1"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <div className="row">
                      <div className="col-md-6 col-lg-4">
                        <div className="card border-none">
                          <img
                            src="/assets/images/cards/InfinityX.png"
                            alt="img"
                            className="img-fluid card-img card-img1"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <div className="row">
                      <div className="col-md-6 col-lg-4">
                        <div className="card border-none">
                          <img
                            src="/assets/images/cards/PDRL.png"
                            alt="img"
                            className="img-fluid card-img card-img1"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="investor-section">
        <div className="container">
          <h5 className="heading text-center">Join over 1000+ Investors</h5>
          <div className="row mt-4">
            <div className="col-lg-4 text-center">
              <Counter endValue={150} prefix="₹ " suffix=" Cr" />
              <p className="subtext">Funds Raised</p>
            </div>
            <div className="col-lg-4 text-center">
              <Counter endValue={5000} suffix="+" />
              <p className="subtext">Active Investors</p>
            </div>
            <div className="col-lg-4 text-center">
              <Counter endValue={32} suffix="+" />
              <p className="subtext">Funded Startups</p>
            </div>
          </div>
          <div className="investor-scroll-container">
            <div className="investor-scroll scroll-left">
              {InvestorData.map((data, index) => (
                <div className="investor-data" key={`scroll1-${index}`}>
                  {data.name}
                </div>
              ))}
              {InvestorData.map((data, index) => (
                <div
                  className="investor-data"
                  key={`scroll1-duplicate-${index}`}
                >
                  {data.name}
                </div>
              ))}
              {InvestorData.map((data, index) => (
                <div
                  className="investor-data"
                  key={`scroll1-duplicate-${index}`}
                >
                  {data.name}
                </div>
              ))}
              {InvestorData.map((data, index) => (
                <div
                  className="investor-data"
                  key={`scroll1-duplicate-${index}`}
                >
                  {data.name}
                </div>
              ))}
              {InvestorData.map((data, index) => (
                <div
                  className="investor-data"
                  key={`scroll1-duplicate-${index}`}
                >
                  {data.name}
                </div>
              ))}
            </div>

            <div className="investor-scroll scroll-right">
              {InvestorData2.map((data, index) => (
                <div className="investor-data" key={`scroll2-${index}`}>
                  {data.name}
                </div>
              ))}
              {InvestorData2.map((data, index) => (
                <div
                  className="investor-data"
                  key={`scroll2-duplicate-${index}`}
                >
                  {data.name}
                </div>
              ))}
              {InvestorData2.map((data, index) => (
                <div
                  className="investor-data"
                  key={`scroll2-duplicate-${index}`}
                >
                  {data.name}
                </div>
              ))}
              {InvestorData2.map((data, index) => (
                <div
                  className="investor-data"
                  key={`scroll2-duplicate-${index}`}
                >
                  {data.name}
                </div>
              ))}
              {InvestorData2.map((data, index) => (
                <div
                  className="investor-data"
                  key={`scroll2-duplicate-${index}`}
                >
                  {data.name}
                </div>
              ))}
              {InvestorData2.map((data, index) => (
                <div
                  className="investor-data"
                  key={`scroll2-duplicate-${index}`}
                >
                  {data.name}
                </div>
              ))}
              {InvestorData2.map((data, index) => (
                <div
                  className="investor-data"
                  key={`scroll2-duplicate-${index}`}
                >
                  {data.name}
                </div>
              ))}
            </div>

            {/* <div className="investor-scroll scroll-left">
                            {InvestorData3.map((data, index) => (
                                <div className="investor-data" key={`scroll3-${index}`}>
                                    {data.name}
                                </div>
                            ))}
                            {InvestorData3.map((data, index) => (
                                <div className="investor-data" key={`scroll3-duplicate-${index}`}>
                                    {data.name}
                                </div>
                            ))}
                        </div> */}
          </div>
        </div>
      </section>

      <section className="fund-section hide-mobile job-roles">
        <div className="container">
          <div className="text-center">
            <h4 className="heading">Funded Startups on BizDateUp</h4>
            <p className="content mt-3">
              We partner with visionary founders driving innovation across
              industries.
            </p>
          </div>

          <div
            id="carouselExampleIndicators1"
            className="carousel slide"
            data-bs-ride="carousel"
          >
            <button
              className="carousel-control-prev"
              type="button"
              data-bs-target="#carouselExampleIndicators1"
              data-bs-slide="prev"
            >
              <span className="carousel-control-prev-icon" aria-hidden="true">
                <i className="bi bi-chevron-left"></i>
              </span>
              <span className="visually-hidden">Previous</span>
            </button>
            <div className="carousel-inner">
              <div className="carousel-item active">
                <div className="row">
                  <div className="col-md-6 col-lg-4">
                    <div className="card">
                      <img
                        src="/assets/images/cards/battre.png"
                        alt="img"
                        className="img-fluid card-img card-img1"
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4">
                    <div className="card">
                      <img
                        src="/assets/images/cards/Stylework.png"
                        alt="img"
                        className="img-fluid card-img"
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4 mx-auto">
                    <div className="card">
                      <img
                        src="/assets/images/cards/Immersive.png"
                        alt="img"
                        className="img-fluid card-img"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="carousel-item">
                <div className="row">
                  <div className="col-md-6 col-lg-4">
                    <div className="card">
                      <img
                        src="/assets/images/cards/InfinityX.png"
                        alt="img"
                        className="img-fluid card-img card-img1"
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4">
                    <div className="card">
                      <img
                        src="/assets/images/cards/SQUARE.png"
                        alt="img"
                        className="img-fluid card-img"
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4 mx-auto">
                    <div className="card">
                      <img
                        src="/assets/images/cards/myints.png"
                        alt="img"
                        className="img-fluid card-img"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="carousel-item">
                <div className="row">
                  <div className="col-md-6 col-lg-4">
                    <div className="card">
                      <img
                        src="/assets/images/cards/PDRL.png"
                        alt="img"
                        className="img-fluid card-img card-img1"
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4">
                    <div className="card">
                      <img
                        src="/assets/images/cards/Shubhag.png"
                        alt="img"
                        className="img-fluid card-img"
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4 mx-auto">
                    <div className="card">
                      <img
                        src="/assets/images/cards/Immersive.png"
                        alt="img"
                        className="img-fluid card-img"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <button
              className="carousel-control-next"
              type="button"
              data-bs-target="#carouselExampleIndicators1"
              data-bs-slide="next"
            >
              <span className="carousel-control-next-icon" aria-hidden="true">
                <i className="bi bi-chevron-right"></i>
              </span>
              <span className="visually-hidden">Next</span>
            </button>
          </div>
        </div>
      </section>

      <section className="fund-section home-fund-section hide-desktop job-roles">
        <div className="container">
          <div className="text-center">
            <h4 className="heading">Funded Startups on BizDateUp</h4>
            <p className="content mt-3">
              We partner with visionary founders driving innovation across
              industries.
            </p>
          </div>

          <div
            id="carouselExampleIndicators2"
            className="carousel slide"
            data-bs-ride="carousel"
          >
            <button
              className="carousel-control-prev"
              type="button"
              data-bs-target="#carouselExampleIndicators2"
              data-bs-slide="prev"
            >
              <span className="carousel-control-prev-icon" aria-hidden="true">
                <i className="bi bi-chevron-left"></i>
              </span>
              <span className="visually-hidden">Previous</span>
            </button>
            <div className="carousel-inner">
              <div className="carousel-item active">
                <div className="row">
                  <div className="col-md-6 col-lg-4">
                    <div className="card">
                      <img
                        src="/assets/images/cards/battre.png"
                        alt="img"
                        className="img-fluid card-img card-img1"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="carousel-item">
                <div className="row">
                  <div className="col-md-6 col-lg-4">
                    <div className="card">
                      <img
                        src="/assets/images/cards/InfinityX.png"
                        alt="img"
                        className="img-fluid card-img card-img1"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="carousel-item">
                <div className="row">
                  <div className="col-md-6 col-lg-4">
                    <div className="card">
                      <img
                        src="/assets/images/cards/Shubhag.png"
                        alt="img"
                        className="img-fluid card-img card-img1"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="carousel-item">
                <div className="row">
                  <div className="col-md-6 col-lg-4">
                    <div className="card">
                      <img
                        src="/assets/images/cards/SQUARE.png"
                        alt="img"
                        className="img-fluid card-img card-img1"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="carousel-item">
                <div className="row">
                  <div className="col-md-6 col-lg-4">
                    <div className="card">
                      <img
                        src="/assets/images/cards/PDRL.png"
                        alt="img"
                        className="img-fluid card-img card-img1"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <button
              className="carousel-control-next"
              type="button"
              data-bs-target="#carouselExampleIndicators2"
              data-bs-slide="next"
            >
              <span className="carousel-control-next-icon" aria-hidden="true">
                <i className="bi bi-chevron-right"></i>
              </span>
              <span className="visually-hidden">Next</span>
            </button>
          </div>
        </div>
      </section>
      <hr className="line"></hr>

      <section className="profit-section">
        <div className="container">
          <div className="text-center">
            <h4 className="heading">Growing Your ₹10 Lakhs to ₹10 Crores</h4>
            <p className="content mt-3">
              Don't Miss Out on the Next Zomato! Invest in Tomorrow's
              Game-Changers Today
            </p>
          </div>
          <div className="profit-content">
            <div id="carouselExampleIndicators4" className="carousel slide">
              <div className="carousel-indicators">
                {carouselItems.map((item, index) => (
                  <button
                    key={item.id}
                    type="button"
                    data-bs-target="#carouselExampleIndicators4"
                    data-bs-slide-to={index}
                    className={index === 0 ? "active" : ""}
                    aria-current={index === 0 ? "true" : "false"}
                    aria-label={`Slide ${index + 1}`}
                  ></button>
                ))}
              </div>
              <div className="carousel-inner d-flex">
                <button
                  className="carousel-control-prev"
                  type="button"
                  data-bs-target="#carouselExampleIndicators4"
                  data-bs-slide="prev"
                  style={{ color: "#E33845" }}
                  onClick={clearInputs} // Call the function to clear inputs
                >
                  <span
                    className="carousel-control-prev-icon"
                    aria-hidden="true"
                  >
                    <i className="bi bi-chevron-left"></i>
                  </span>
                  <span className="visually-hidden">Previous</span>
                </button>
                <div className="carousel-inner">
                  {carouselItems.map((item, index) => (
                    <div
                      key={item.id}
                      className={`carousel-item ${index === 0 ? "active" : ""}`}
                    >
                      <div className="row">
                        <div className="col-lg-6">
                          <img
                            src={item.image}
                            alt="img"
                            className="card-img profitsection-slider-image"
                          />
                        </div>
                        <div className="col-lg-6 mt-5 mt-lg-0 d-flex flex-column align-items-center justify-content-center">
                          <img
                            src={item.logo}
                            alt="img"
                            style={{ width: "100px" }}
                          />
                          <h3
                            className="content-heading"
                            style={{ color: item.color }}
                          >
                            {item.heading}
                          </h3>
                          {item.investmentLabel !== "" &&
                            item.fortuneLabel !== "" && (
                              <div className="carousel-inner-field mt-4 mb-5">
                                <div className="">
                                  <p>
                                    <label>{item.investmentLabel}</label>
                                  </p>
                                  <input
                                    type="text"
                                    placeholder="Enter Your Amount"
                                    value={investmentAmount}
                                    onChange={(e) =>
                                      setInvestmentAmount(e.target.value)
                                    }
                                  />
                                </div>
                                <div className="carousel-inner-arrow">
                                  <p>
                                    <label></label>
                                  </p>
                                  <div className="arrow">
                                    <img
                                      src="/assets/images/card-arrow.png"
                                      alt="icon"
                                    />
                                  </div>
                                </div>
                                <div className="mt-lg-0">
                                  <p>
                                    <label>{item.fortuneLabel}</label>
                                  </p>
                                  <input
                                    type="text"
                                    placeholder=""
                                    value={fortuneAmount} // Bind this field to the calculated value
                                    readOnly
                                  />
                                </div>
                              </div>
                            )}
                          {item.buttonIcon !== "" ? (
                            <button
                              className="btn mt-5"
                              onClick={() =>
                                handleCalculate(item.calculationFactor)
                              }
                            >
                              <img
                                src={item.buttonIcon}
                                alt="icon"
                                className="me-2"
                              />
                              {item.buttonLabel}
                            </button>
                          ) : (
                            <button
                              className="btn"
                              onClick={() =>
                                handleCalculate(item.calculationFactor)
                              }
                            >
                              {item.buttonLabel}
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <button
                  className="carousel-control-next"
                  type="button"
                  data-bs-target="#carouselExampleIndicators4"
                  data-bs-slide="next"
                  style={{ color: "#E33845" }}
                  onClick={clearInputs} // Call the function to clear inputs
                >
                  <span
                    className="carousel-control-next-icon"
                    aria-hidden="true"
                  >
                    <i className="bi bi-chevron-right"></i>
                  </span>
                  <span className="visually-hidden">Next</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <hr className="line"></hr>
      {/* <section className="local-favourite-section">
                <div className="container">
                    <div className="text-center">
                        <h4 className="heading">From <span>Tomorrow’s Breakthrough Giants</span> to Your Local Favorites</h4>
                        <p className="content mt-3">Don't Miss Out on the Next Zomato! Invest in Tomorrow's Game-Changers Today</p>
                    </div>
                    <div className="local-favourite-section-content">
                        <div className="row">
                            <div className="col-lg-6">
                                <h4 className="subheading">Explore Investment Sectors</h4>
                                <div className="mt-5">
                                    <div className="d-flex flex-wrap">
                                        {favourites.map((data, index) => (
                                            <div className="label" key={index}>
                                                {data.name}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div id="carouselExampleIndicators3" className="carousel slide">
                                    <div className="carousel-indicators">
                                        <button type="button" data-bs-target="#carouselExampleIndicators3" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                                        <button type="button" data-bs-target="#carouselExampleIndicators3" data-bs-slide-to="1" aria-label="Slide 2"></button>
                                        <button type="button" data-bs-target="#carouselExampleIndicators3" data-bs-slide-to="2" aria-label="Slide 3"></button>
                                        <button type="button" data-bs-target="#carouselExampleIndicators3" data-bs-slide-to="3" aria-label="Slide 4"></button>
                                    </div>
                                    <div className="carousel-inner">
                                        <div className="carousel-item active">
                                            <div className="card">
                                                <img src="/assets/images/cards/ZEVO.png" alt="img" className="card-img" /> */}
      {/* <div className="card-bottom-content">
                                                    <div className="medi-content">
                                                        <img src="/assets/images/plus-image.png" alt="img" className="plus-img" />
                                                        Medi ++
                                                    </div>
                                                    <p className="card-content my-4">Bringing doctors, patients, and AI-powered diagnostics together for ultimate healthcare access.</p>
                                                    <div className="row">
                                                        <div className="col-lg-6">
                                                            <h5 className="investors">₹ 7,00,00</h5>
                                                            <p className="investors-content">Invested</p>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <h5 className="investors">80</h5>
                                                            <p className="investors-content">Investors</p>
                                                        </div>
                                                    </div>
                                                    <button className="btn mt-3">Explore Healthcare Startups</button>
                                                </div> */}
      {/* </div>
                                        </div>
                                        <div className="carousel-item">
                                            <div className="card">
                                                <img src="/assets/images/cards/Twyn.png" alt="img" className="card-img" /> */}
      {/* <div className="card-bottom-content">
                                                    <div className="medi-content">
                                                        <img src="/assets/images/plus-image.png" alt="img" className="plus-img" />
                                                        Medi ++
                                                    </div>
                                                    <p className="card-content my-4">Bringing doctors, patients, and AI-powered diagnostics together for ultimate healthcare access.</p>
                                                    <div className="row">
                                                        <div className="col-lg-6">
                                                            <h5 className="investors">₹ 7,00,00</h5>
                                                            <p className="investors-content">Invested</p>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <h5 className="investors">80</h5>
                                                            <p className="investors-content">Investors</p>
                                                        </div>
                                                    </div>
                                                    <button className="btn mt-3">Explore Healthcare Startups</button>
                                                </div> */}
      {/* </div>

                                        </div>
                                        <div className="carousel-item">
                                            <div className="card">
                                                <img src="/assets/images/cards/Tutti frutti.png" alt="img" className="card-img" /> */}
      {/* <div className="card-bottom-content">
                                                    <div className="medi-content">
                                                        <img src="/assets/images/plus-image.png" alt="img" className="plus-img" />
                                                        Medi ++
                                                    </div>
                                                    <p className="card-content my-4">Bringing doctors, patients, and AI-powered diagnostics together for ultimate healthcare access.</p>
                                                    <div className="row">
                                                        <div className="col-lg-6">
                                                            <h5 className="investors">₹ 7,00,00</h5>
                                                            <p className="investors-content">Invested</p>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <h5 className="investors">80</h5>
                                                            <p className="investors-content">Investors</p>
                                                        </div>
                                                    </div>
                                                    <button className="btn mt-3">Explore Healthcare Startups</button>
                                                </div> */}
      {/* </div>
                                        </div>
                                        <div className="carousel-item">
                                            <div className="card">
                                                <img src="/assets/images/cards/Stylework.png" alt="img" className="card-img" /> */}
      {/* <div className="card-bottom-content">
                                                    <div className="medi-content">
                                                        <img src="/assets/images/plus-image.png" alt="img" className="plus-img" />
                                                        Medi ++
                                                    </div>
                                                    <p className="card-content my-4">Bringing doctors, patients, and AI-powered diagnostics together for ultimate healthcare access.</p>
                                                    <div className="row">
                                                        <div className="col-lg-6">
                                                            <h5 className="investors">₹ 7,00,00</h5>
                                                            <p className="investors-content">Invested</p>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <h5 className="investors">80</h5>
                                                            <p className="investors-content">Investors</p>
                                                        </div>
                                                    </div>
                                                    <button className="btn mt-3">Explore Healthcare Startups</button>
                                                </div> */}
      {/* </div>
                                        </div>
                                    </div>
                                    <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators3" data-bs-slide="prev">
                                        <span className="carousel-control-prev-icon" aria-hidden="true">
                                        </span>
                                        <span className="visually-hidden">Previous</span>
                                    </button>
                                    <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators3" data-bs-slide="next">
                                        <span className="carousel-control-next-icon" aria-hidden="true">
                                            <i className="bi bi-chevron-right"></i>
                                        </span>
                                        <span className="visually-hidden">Next</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
      <section className="why-bizdateup-section">
        <div className="container">
          <div className="text-center">
            <h4 className="heading">Invest with Us</h4>
            <p className="content mt-3">
              Support founders, build your portfolio
            </p>
          </div>
          <div className="row mt-5 why-bizdateup-section-div">
            {whyBizDateUp.map((data, index) => (
              <div className="col-lg-4 mt-5 text-center" key={index}>
                <img src={data.src} alt="img" className="mx-auto" />
                <h6 className="subheading my-4">{data.heading}</h6>
                <p className="subcontent">{data.content}</p>
              </div>
            ))}
          </div>
        </div>
      </section>
      <hr className="line"></hr>
      {/* <section className="risk-section">
                <div className="container">
                    <div className="risk-content">
                        <div className="row">
                            <div className="col-lg-5">
                                <h4 className="subheading">Invest Wisely: Know the Risks</h4>
                                <p className="subcontent my-4">Startups carry higher risks than public companies, and even the most promising ventures can fail. Only invest what you can afford to lose, and plan for the long haul. Be prepared for both big wins and the possibility of losing everything.</p>
                                <button className="btn">Learn more in our investor FAQ</button>
                            </div>
                            <div className="col-lg-7">
                                <img src="/assets/images/profit.png" alt="img" className="ms-lg-auto mt-5 mt-lg-0 d-flex justify-content-lg-end" />
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
      <section className="investment-section">
        <div className="container">
          <div className="text-center">
            <h4 className="heading">New to Investments</h4>
            <p className="content mt-3">Here’s how it works on BizDateUp</p>
          </div>
          {investments.map((data, index) => (
            <div className="row" key={index}>
              <div className="col-lg-6">
                <div className="img d-flex flex-row align-items-center">
                  <img src={data.src} className="img-fluid" alt="img" />
                </div>
              </div>
              <div className="col-lg-6 d-flex flex-column justify-content-center">
                <h5 className="subheading">{data.heading}</h5>
                <p className="subcontent mt-2">{data.content}</p>
              </div>
            </div>
          ))}
        </div>
      </section>
      <hr className="line"></hr>
      <section className="blog-section blog-section-carousel">
        <div className="container">
          <div className="text-center">
            <h4 className="head">
              Success Stories real Investors, real Returns
            </h4>
          </div>
          <div className="recent-blog-posts">
            <div id="carouselExampleIndicators12" className="carousel slide">
              <button
                className="carousel-control-prev"
                type="button"
                data-bs-target="#carouselExampleIndicators12"
                data-bs-slide="prev"
              >
                <span className="carousel-control-prev-icon" aria-hidden="true">
                  <i className="bi bi-chevron-left"></i>
                </span>
                <span className="visually-hidden">Previous</span>
              </button>
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <div className="row">
                    {/* <div className="col-md-6">
                                            <div className="blogs-right-img">
                                                <img src="/assets/images/blog-post.png" className="img-fluid" alt="img" />
                                            </div>
                                        </div> */}
                    <div className="">
                      <h6 className="heading text-center">Sanket Gaokar</h6>
                      <h6 className="subheading mt-2 text-center">
                        Investment In TWYN
                      </h6>
                      <p className="right-content mt-3">
                        Investing in TWYN, a remarkable SAAS platform through
                        BizDateUp, has truly been a game-changer for me as an
                        investor. BizDateUp's support and guidance throughout
                        this journey have been invaluable, enabling me to make
                        informed decisions and witness substantial growth in my
                        investment. BizDateUp has made it a seamless experience
                        to explore and understand the potential of TWYN. It's
                        incredibly exciting to be part of a company that is
                        revolutionizing the industry.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="carousel-item">
                  <div className="row">
                    {/* <div className="col-md-6">
                                            <div className="blogs-right-img">
                                                <img src="/assets/images/blog-post.png" className="img-fluid" alt="img" />
                                            </div>
                                        </div> */}
                    <div className="">
                      <h6 className="heading text-center">
                        Tanishq Chandaliya
                      </h6>
                      <h6 className="subheading mt-2 text-center">
                        Investment In BizDateUp
                      </h6>
                      <p className="right-content mt-3">
                        As an investor, I can confidently say that BizDateUp has
                        been a game-changer in my journey toward financial
                        success. it provided me with a unique opportunity to
                        invest in PDRL, an incredible drone tech startup, and
                        the results have surpassed my expectations. BizDateUp's
                        user-friendly interface, thorough vetting process, and
                        comprehensive market analysis gave me the confidence to
                        make an informed investment decision.
                      </p>
                    </div>
                    {/* <div className="">
                                            <div className="blogs-content">
                                                <div className="mt-3 mt-md-0">
                                                    <h6 className="heading">Tanishq Chandaliya • 1 Jan 2023</h6>
                                                    <h6 className="subheading">Investment In BizDateUp</h6>
                                                </div>
                                            </div>
                                            <p className="right-content">As an investor, I can confidently say that BizDateUp has been a game-changer in my journey toward financial success. it provided me with a unique opportunity to invest in PDRL, an incredible drone tech startup, and the results have surpassed my expectations. BizDateUp's user-friendly interface, thorough vetting process, and comprehensive market analysis gave me the confidence to make an informed investment decision.</p>
                                        </div> */}
                  </div>
                </div>
                <div className="carousel-item">
                  <div className="row">
                    {/* <div className="col-md-6">
                                            <div className="blogs-right-img">
                                                <img src="/assets/images/blog-post.png" className="img-fluid" alt="img" />
                                            </div>
                                        </div> */}
                    <div className="">
                      <h6 className="heading text-center">Karan Singh</h6>
                      <h6 className="subheading mt-2 text-center">
                        Investment In Revamp Moto
                      </h6>
                      <p className="right-content mt-3 ">
                        Investing in Revamp Moto through BizDateUp has been a
                        great experience. The platform's user-friendly interface
                        and comprehensive information on the EV startup allowed
                        me to make an informed investment decision. Thanks to
                        BizDateUp's regular updates and analysis, I have
                        witnessed substantial growth in my investment. Revamp
                        Moto's dedication to revolutionizing the electric
                        vehicle industry and BizDateUp's support have made this
                        investment experience truly rewarding.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <button
                className="carousel-control-next"
                type="button"
                data-bs-target="#carouselExampleIndicators12"
                data-bs-slide="next"
              >
                <span className="carousel-control-next-icon" aria-hidden="true">
                  <i className="bi bi-chevron-right"></i>
                </span>
                <span className="visually-hidden">Next</span>
              </button>
            </div>
          </div>
        </div>
      </section>

      <hr className="line"></hr>
      <section className="investments-on-bizdateup">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 offset-lg-1">
              <h6 className="subheading">Everything you need to know</h6>
              <h4 className="heading my-4">
                Effortless, Fast & Rewarding Investments on BizDateUp
              </h4>
              <p className="content">
                Experience a seamless journey as you invest in Startups and
                syndicates, designed to be simple, quick, and enjoyable.
              </p>
              <ul
                className="nav flex-column nav-tabs mt-5"
                id="myTab"
                role="tablist"
              >
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link active"
                    id="startup-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#startup-tab-pane"
                    type="button"
                    role="tab"
                    aria-controls="startup-tab-pane"
                    aria-selected="true"
                  >
                    Invest in Startups
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="syndicates-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#syndicate-tab-pane"
                    type="button"
                    role="tab"
                    aria-controls="syndicate-tab-pane"
                    aria-selected="false"
                  >
                    Invest in Syndicates
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="investments-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#investments-tab-pane"
                    type="button"
                    role="tab"
                    aria-controls="investments-tab-pane"
                    aria-selected="false"
                  >
                    Track your Investments
                  </button>
                </li>
              </ul>
            </div>
            <div className="col-lg-5">
              <div className="tab-content" id="myTabContent">
                <div
                  className="tab-pane fade show active"
                  id="startup-tab-pane"
                  role="tabpanel"
                  aria-labelledby="home-tab"
                  tabIndex="0"
                >
                  <img
                    src="/assets/images/investment-on-bizdateup_1.svg"
                    className="ms-lg-auto d-flex justify-content-end investment-on-bizdateup "
                    alt="img"
                  />
                </div>
                <div
                  className="tab-pane fade"
                  id="syndicate-tab-pane"
                  role="tabpanel"
                  aria-labelledby="syndicate-tab"
                  tabIndex="0"
                >
                  <img
                    src="/assets/images/investment-on-bizdateup_2.svg"
                    className="ms-lg-auto d-flex justify-content-end investment-on-bizdateup"
                    alt="img"
                  />
                </div>
                <div
                  className="tab-pane fade"
                  id="investments-tab-pane"
                  role="tabpanel"
                  aria-labelledby="investments-tab"
                  tabIndex="0"
                >
                  <img
                    src="/assets/images/investment-on-bizdateup_3.svg"
                    className="ms-lg-auto d-flex justify-content-end investment-on-bizdateup"
                    alt="img"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <hr className="line"></hr>
      <section className="fund-section hide-mobile job-roles mb-5">
        <div className="container">
          <div className="text-center">
            <h4 className="heading">Featured On</h4>
          </div>

          <div
            id="carouselExampleIndicators8"
            className="carousel slide"
            data-bs-ride="carousel"
          >
            <button
              className="carousel-control-prev"
              type="button"
              data-bs-target="#carouselExampleIndicators8"
              data-bs-slide="prev"
            >
              <span className="carousel-control-prev-icon" aria-hidden="true">
                <i className="bi bi-chevron-left"></i>
              </span>
              <span className="visually-hidden">Previous</span>
            </button>
            <div className="carousel-inner">
              <div className="carousel-item active">
                <div className="row">
                  <div className="col-md-6 col-lg-4 mx-auto all-blogs-section">
                    <div className="card blog-posts-news">
                      <div className=" box-news">
                        {" "}
                        {/* Use col-6 for all screen sizes */}
                        <div className="blogs-img">
                          <a
                            href={
                              "https://inc42.com/buzz/angel-network-bizdateup-launches-inr-200-cr-fund-to-back-non-metro-startups/"
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              src={"/assets/images/INC42.png"}
                              className="img-fluid"
                              alt="blog"
                            />
                          </a>
                        </div>
                        <div className="blogs-content">
                          <div>
                            <h6 className="content">
                              {
                                "Angel Network BizDateUp Launches INR 200 Cr Fund To Back Non-Metro Startups"
                              }
                            </h6>
                          </div>
                          <div>
                            <a
                              href={
                                "https://inc42.com/buzz/angel-network-bizdateup-launches-inr-200-cr-fund-to-back-non-metro-startups/"
                              }
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                src="/assets/images/arrow-icon.svg"
                                alt="arrow icon"
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4 mx-auto all-blogs-section">
                    <div className="card blog-posts-news">
                      <div className=" box-news">
                        {" "}
                        {/* Use col-6 for all screen sizes */}
                        <div className="blogs-img">
                          <a
                            href={
                              "https://www.entrepreneur.com/en-in/news-and-trends/bizdateup-founders-jeet-chandan-and-meet-jain-acquire-stake/480601"
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              src={"/assets/images/Entreprenure.png"}
                              className="img-fluid"
                              alt="blog"
                            />
                          </a>
                        </div>
                        <div className="blogs-content">
                          <div>
                            <h6 className="content">
                              {
                                "BizDateUp Founders Jeet Chandan and Meet Jain Acquire Stake in Swiggy During Pre-IPO Round"
                              }
                            </h6>
                          </div>
                          <div>
                            <a
                              href={
                                "https://www.entrepreneur.com/en-in/news-and-trends/bizdateup-founders-jeet-chandan-and-meet-jain-acquire-stake/480601"
                              }
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                src="/assets/images/arrow-icon.svg"
                                alt="arrow icon"
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4 mx-auto all-blogs-section">
                    <div className="card blog-posts-news">
                      <div className=" box-news">
                        {" "}
                        {/* Use col-6 for all screen sizes */}
                        <div className="blogs-img">
                          <a
                            href={
                              "https://www.financialexpress.com/life/lifestyle-know-jeet-chandan-and-meet-jain-founders-of-bizdateup-heres-everything-about-their-journey-lifestyle-and-more-3420204/"
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              src={"/assets/images/FE leisure.png"}
                              className="img-fluid"
                              alt="blog"
                            />
                          </a>
                        </div>
                        <div className="blogs-content">
                          <div>
                            <h6 className="content">
                              {
                                "Know Jeet Chandan and Meet Jain, founders of BizDateUp – here’s everything about their journey, lifestyle, and more"
                              }
                            </h6>
                          </div>
                          <div>
                            <a
                              href={
                                "https://www.financialexpress.com/life/lifestyle-know-jeet-chandan-and-meet-jain-founders-of-bizdateup-heres-everything-about-their-journey-lifestyle-and-more-3420204/"
                              }
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                src="/assets/images/arrow-icon.svg"
                                alt="arrow icon"
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="carousel-item">
                <div className="row">
                  <div className="col-md-6 col-lg-4 mx-auto all-blogs-section">
                    <div className="card blog-posts-news">
                      <div className=" box-news">
                        {" "}
                        {/* Use col-6 for all screen sizes */}
                        <div className="blogs-img">
                          <a
                            href={
                              "https://indianstartupnews.com/funding/bizdateup-joins-twyn-s-pre-series-a-funding-round-with-a-usd-235000-investment-2000181"
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              src={"/assets/images/ISN.png"}
                              className="img-fluid"
                              alt="blog"
                            />
                          </a>
                        </div>
                        <div className="blogs-content">
                          <div>
                            <h6 className="content">
                              {
                                "Bizdateup joins Twyn's $1.25 million pre-Series A funding round with a $235,000 investment"
                              }
                            </h6>
                          </div>
                          <div>
                            <a
                              href={
                                "https://indianstartupnews.com/funding/bizdateup-joins-twyn-s-pre-series-a-funding-round-with-a-usd-235000-investment-2000181"
                              }
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                src="/assets/images/arrow-icon.svg"
                                alt="arrow icon"
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4 mx-auto all-blogs-section">
                    <div className="card blog-posts-news">
                      <div className=" box-news">
                        {" "}
                        {/* Use col-6 for all screen sizes */}
                        <div className="blogs-img">
                          <a
                            href={
                              "https://theprint.in/ani-press-releases/young-titans-jeet-chandan-meet-jain-spearhead-bizdateups-25mn-aif-cat-1-fund-announcement-at-prestigious-startup-investment-conclave/1980875/"
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              src={"/assets/images/The print.png"}
                              className="img-fluid"
                              alt="blog"
                            />
                          </a>
                        </div>
                        <div className="blogs-content">
                          <div>
                            <h6 className="content">
                              {
                                "Young Titans Jeet Chandan & Meet Jain Spearhead BizDateUp’s $25Mn AIF CAT 1 Fund Announcement at Prestigious Startup Investment Conclave"
                              }
                            </h6>
                          </div>
                          <div>
                            <a
                              href={
                                "https://theprint.in/ani-press-releases/young-titans-jeet-chandan-meet-jain-spearhead-bizdateups-25mn-aif-cat-1-fund-announcement-at-prestigious-startup-investment-conclave/1980875/"
                              }
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                src="/assets/images/arrow-icon.svg"
                                alt="arrow icon"
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4 mx-auto all-blogs-section">
                    <div className="card blog-posts-news">
                      <div className=" box-news">
                        {" "}
                        {/* Use col-6 for all screen sizes */}
                        <div className="blogs-img">
                          <a
                            href={
                              "https://www.theweek.in/news/biz-tech/2022/09/30/jeet-chandan-founder-and-md-of-bizdateup-decodes-what-it-takes-to-be-a-successful-entrepreneur.html"
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              src={"/assets/images/Theweek.png"}
                              className="img-fluid"
                              alt="blog"
                            />
                          </a>
                        </div>
                        <div className="blogs-content">
                          <div>
                            <h6 className="content">
                              {
                                "Jeet Chandan, Founder & MD of BizDateUp, decodes what it takes to be a successful entrepreneur"
                              }
                            </h6>
                          </div>
                          <div>
                            <a
                              href={
                                "https://www.theweek.in/news/biz-tech/2022/09/30/jeet-chandan-founder-and-md-of-bizdateup-decodes-what-it-takes-to-be-a-successful-entrepreneur.html"
                              }
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                src="/assets/images/arrow-icon.svg"
                                alt="arrow icon"
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="carousel-item">
                <div className="row">
                  <div className="col-md-6 col-lg-4 mx-auto all-blogs-section">
                    <div className="card blog-posts-news">
                      <div className=" box-news">
                        {" "}
                        {/* Use col-6 for all screen sizes */}
                        <div className="blogs-img">
                          <a
                            href={
                              "https://entrackr.com/2024/05/bizdateup-technologies-acquires-20-stake-in-duro-green/"
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              src={"/assets/images/Entrackr.png"}
                              className="img-fluid"
                              alt="blog"
                            />
                          </a>
                        </div>
                        <div className="blogs-content">
                          <div>
                            <h6 className="content">
                              {
                                "BizDateUp Technologies acquires 20% stake in Duro Green"
                              }
                            </h6>
                          </div>
                          <div>
                            <a
                              href={
                                "https://entrackr.com/2024/05/bizdateup-technologies-acquires-20-stake-in-duro-green/"
                              }
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                src="/assets/images/arrow-icon.svg"
                                alt="arrow icon"
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4 mx-auto all-blogs-section">
                    <div className="card blog-posts-news">
                      <div className=" box-news">
                        {" "}
                        {/* Use col-6 for all screen sizes */}
                        <div className="blogs-img">
                          <a
                            href={
                              "https://www.entrepreneur.com/en-in/news-and-trends/bizdateup-founders-jeet-chandan-and-meet-jain-acquire-stake/480601"
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              src={"/assets/images/Entreprenure.png"}
                              className="img-fluid"
                              alt="blog"
                            />
                          </a>
                        </div>
                        <div className="blogs-content">
                          <div>
                            <h6 className="content">
                              {
                                "BizDateUp Founders Jeet Chandan and Meet Jain Acquire Stake in Swiggy During Pre-IPO Round"
                              }
                            </h6>
                          </div>
                          <div>
                            <a
                              href={
                                "https://www.entrepreneur.com/en-in/news-and-trends/bizdateup-founders-jeet-chandan-and-meet-jain-acquire-stake/480601"
                              }
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                src="/assets/images/arrow-icon.svg"
                                alt="arrow icon"
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4 mx-auto all-blogs-section">
                    <div className="card blog-posts-news">
                      <div className=" box-news">
                        {" "}
                        {/* Use col-6 for all screen sizes */}
                        <div className="blogs-img">
                          <a
                            href={
                              "https://inc42.com/buzz/angel-network-bizdateup-launches-inr-200-cr-fund-to-back-non-metro-startups/"
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              src={"/assets/images/INC42.png"}
                              className="img-fluid"
                              alt="blog"
                            />
                          </a>
                        </div>
                        <div className="blogs-content">
                          <div>
                            <h6 className="content">
                              {
                                "Angel Network BizDateUp Launches INR 200 Cr Fund To Back Non-Metro Startups"
                              }
                            </h6>
                          </div>
                          <div>
                            <a
                              href={
                                "https://inc42.com/buzz/angel-network-bizdateup-launches-inr-200-cr-fund-to-back-non-metro-startups/"
                              }
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                src="/assets/images/arrow-icon.svg"
                                alt="arrow icon"
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <button
              className="carousel-control-next"
              type="button"
              data-bs-target="#carouselExampleIndicators8"
              data-bs-slide="next"
            >
              <span className="carousel-control-next-icon" aria-hidden="true">
                <i className="bi bi-chevron-right"></i>
              </span>
              <span className="visually-hidden">Next</span>
            </button>
          </div>
        </div>
      </section>

      <section className="fund-section home-fund-section  hide-desktop job-roles">
        <div className="container">
          <div className="text-center">
            <h4 className="heading">Featured On</h4>
          </div>

          <div
            id="carouselExampleIndicators9"
            className="carousel slide"
            data-bs-ride="carousel"
          >
            <button
              className="carousel-control-prev"
              type="button"
              data-bs-target="#carouselExampleIndicators9"
              data-bs-slide="prev"
            >
              <span className="carousel-control-prev-icon" aria-hidden="true">
                <i className="bi bi-chevron-left"></i>
              </span>
              <span className="visually-hidden">Previous</span>
            </button>
            <div className="carousel-inner">
              <div className="carousel-item active">
                <div className="row">
                  <div className="col-md-6 col-lg-4 mx-auto all-blogs-section">
                    <div className="card blog-posts-news">
                      <div className=" box-news">
                        {" "}
                        {/* Use col-6 for all screen sizes */}
                        <div className="blogs-img">
                          <a
                            href={
                              "https://inc42.com/buzz/angel-network-bizdateup-launches-inr-200-cr-fund-to-back-non-metro-startups/"
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              src={"/assets/images/INC42.png"}
                              className="img-fluid"
                              alt="blog"
                            />
                          </a>
                        </div>
                        <div className="blogs-content">
                          <div>
                            <h6 className="content p-4">
                              {
                                "Angel Network BizDateUp Launches INR 200 Cr Fund To Back Non-Metro Startups"
                              }
                            </h6>
                          </div>
                          <div>
                            <a
                              href={
                                "https://inc42.com/buzz/angel-network-bizdateup-launches-inr-200-cr-fund-to-back-non-metro-startups/"
                              }
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                src="/assets/images/arrow-icon.svg"
                                alt="arrow icon"
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="carousel-item">
                <div className="row">
                  <div className="col-md-6 col-lg-4 mx-auto all-blogs-section">
                    <div className="card blog-posts-news">
                      <div className=" box-news">
                        {" "}
                        {/* Use col-6 for all screen sizes */}
                        <div className="blogs-img">
                          <a
                            href={
                              "https://www.entrepreneur.com/en-in/news-and-trends/bizdateup-founders-jeet-chandan-and-meet-jain-acquire-stake/480601"
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              src={"/assets/images/Entreprenure.png"}
                              className="img-fluid"
                              alt="blog"
                            />
                          </a>
                        </div>
                        <div className="blogs-content">
                          <div>
                            <h6 className="content p-4">
                              {
                                "BizDateUp Founders Jeet Chandan and Meet Jain Acquire Stake in Swiggy During Pre-IPO Round"
                              }
                            </h6>
                          </div>
                          <div>
                            <a
                              href={
                                "https://www.entrepreneur.com/en-in/news-and-trends/bizdateup-founders-jeet-chandan-and-meet-jain-acquire-stake/480601"
                              }
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                src="/assets/images/arrow-icon.svg"
                                alt="arrow icon"
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="carousel-item">
                <div className="row">
                  <div className="col-md-6 col-lg-4 mx-auto all-blogs-section">
                    <div className="card blog-posts-news">
                      <div className=" box-news">
                        {" "}
                        {/* Use col-6 for all screen sizes */}
                        <div className="blogs-img">
                          <a
                            href={
                              "https://www.financialexpress.com/life/lifestyle-know-jeet-chandan-and-meet-jain-founders-of-bizdateup-heres-everything-about-their-journey-lifestyle-and-more-3420204/"
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              src={"/assets/images/FE leisure.png"}
                              className="img-fluid"
                              alt="blog"
                            />
                          </a>
                        </div>
                        <div className="blogs-content">
                          <div>
                            <h6 className="content p-4">
                              {
                                "Know Jeet Chandan and Meet Jain, founders of BizDateUp – here’s everything about their journey, lifestyle, and more"
                              }
                            </h6>
                          </div>
                          <div>
                            <a
                              href={
                                "https://www.financialexpress.com/life/lifestyle-know-jeet-chandan-and-meet-jain-founders-of-bizdateup-heres-everything-about-their-journey-lifestyle-and-more-3420204/"
                              }
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                src="/assets/images/arrow-icon.svg"
                                alt="arrow icon"
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="carousel-item">
                <div className="row">
                  <div className="col-md-6 col-lg-4 mx-auto all-blogs-section">
                    <div className="card blog-posts-news">
                      <div className=" box-news">
                        {" "}
                        {/* Use col-6 for all screen sizes */}
                        <div className="blogs-img">
                          <a
                            href={
                              "https://entrackr.com/2024/05/bizdateup-technologies-acquires-20-stake-in-duro-green/"
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              src={"/assets/images/Entrackr.png"}
                              className="img-fluid"
                              alt="blog"
                            />
                          </a>
                        </div>
                        <div className="blogs-content">
                          <div>
                            <h6 className="content p-4">
                              {
                                "BizDateUp Technologies acquires 20% stake in Duro Green"
                              }
                            </h6>
                          </div>
                          <div>
                            <a
                              href={
                                "https://entrackr.com/2024/05/bizdateup-technologies-acquires-20-stake-in-duro-green/"
                              }
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                src="/assets/images/arrow-icon.svg"
                                alt="arrow icon"
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="carousel-item">
                <div className="row">
                  <div className="col-md-6 col-lg-4 mx-auto all-blogs-section">
                    <div className="card blog-posts-news">
                      <div className=" box-news">
                        {" "}
                        {/* Use col-6 for all screen sizes */}
                        <div className="blogs-img">
                          <a
                            href={
                              "https://www.entrepreneur.com/en-in/news-and-trends/bizdateup-founders-jeet-chandan-and-meet-jain-acquire-stake/480601"
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              src={"/assets/images/Entreprenure.png"}
                              className="img-fluid"
                              alt="blog"
                            />
                          </a>
                        </div>
                        <div className="blogs-content">
                          <div>
                            <h6 className="content p-4">
                              {
                                "BizDateUp Founders Jeet Chandan and Meet Jain Acquire Stake in Swiggy During Pre-IPO Round"
                              }
                            </h6>
                          </div>
                          <div>
                            <a
                              href={
                                "https://www.entrepreneur.com/en-in/news-and-trends/bizdateup-founders-jeet-chandan-and-meet-jain-acquire-stake/480601"
                              }
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                src="/assets/images/arrow-icon.svg"
                                alt="arrow icon"
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="carousel-item">
                <div className="row">
                  <div className="col-md-6 col-lg-4 mx-auto all-blogs-section">
                    <div className="card blog-posts-news">
                      <div className=" box-news">
                        {" "}
                        {/* Use col-6 for all screen sizes */}
                        <div className="blogs-img">
                          <a
                            href={
                              "https://theprint.in/ani-press-releases/young-titans-jeet-chandan-meet-jain-spearhead-bizdateups-25mn-aif-cat-1-fund-announcement-at-prestigious-startup-investment-conclave/1980875/"
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              src={"/assets/images/The print.png"}
                              className="img-fluid"
                              alt="blog"
                            />
                          </a>
                        </div>
                        <div className="blogs-content">
                          <div>
                            <h6 className="content p-4">
                              {
                                "Young Titans Jeet Chandan & Meet Jain Spearhead BizDateUp’s $25Mn AIF CAT 1 Fund Announcement at Prestigious Startup Investment Conclave"
                              }
                            </h6>
                          </div>
                          <div>
                            <a
                              href={
                                "https://theprint.in/ani-press-releases/young-titans-jeet-chandan-meet-jain-spearhead-bizdateups-25mn-aif-cat-1-fund-announcement-at-prestigious-startup-investment-conclave/1980875/"
                              }
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                src="/assets/images/arrow-icon.svg"
                                alt="arrow icon"
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="carousel-item">
                <div className="row">
                  <div className="col-md-6 col-lg-4 mx-auto all-blogs-section">
                    <div className="card blog-posts-news">
                      <div className=" box-news">
                        {" "}
                        {/* Use col-6 for all screen sizes */}
                        <div className="blogs-img">
                          <a
                            href={
                              "https://indianstartupnews.com/funding/bizdateup-joins-twyn-s-pre-series-a-funding-round-with-a-usd-235000-investment-2000181"
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              src="/assets/images/ISN.png"
                              className="img-fluid"
                              alt="blog"
                            />
                          </a>
                        </div>
                        <div className="blogs-content">
                          <div>
                            <h6 className="content p-4">
                              {
                                "Bizdateup joins Twyn's $1.25 million pre-Series A funding round with a $235,000 investment"
                              }
                            </h6>
                          </div>
                          <div>
                            <a
                              href={
                                "https://indianstartupnews.com/funding/bizdateup-joins-twyn-s-pre-series-a-funding-round-with-a-usd-235000-investment-2000181"
                              }
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                src="/assets/images/arrow-icon.svg"
                                alt="arrow icon"
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="carousel-item">
                <div className="row">
                  <div className="col-md-6 col-lg-4 mx-auto all-blogs-section">
                    <div className="card blog-posts-news">
                      <div className=" box-news">
                        {" "}
                        {/* Use col-6 for all screen sizes */}
                        <div className="blogs-img">
                          <a
                            href={
                              "https://www.theweek.in/news/biz-tech/2022/09/30/jeet-chandan-founder-and-md-of-bizdateup-decodes-what-it-takes-to-be-a-successful-entrepreneur.html"
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              src={"/assets/images/Theweek.png"}
                              className="img-fluid"
                              alt="blog"
                            />
                          </a>
                        </div>
                        <div className="blogs-content">
                          <div>
                            <h6 className="content p-4">
                              {
                                "Jeet Chandan, Founder & MD of BizDateUp, decodes what it takes to be a successful entrepreneur"
                              }
                            </h6>
                          </div>
                          <div>
                            <a
                              href={
                                "https://www.theweek.in/news/biz-tech/2022/09/30/jeet-chandan-founder-and-md-of-bizdateup-decodes-what-it-takes-to-be-a-successful-entrepreneur.html"
                              }
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                src="/assets/images/arrow-icon.svg"
                                alt="arrow icon"
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <button
              className="carousel-control-next"
              type="button"
              data-bs-target="#carouselExampleIndicators9"
              data-bs-slide="next"
            >
              <span className="carousel-control-next-icon" aria-hidden="true">
                <i className="bi bi-chevron-right"></i>
              </span>
              <span className="visually-hidden">Next</span>
            </button>
          </div>
        </div>
      </section>

      {/* <section className="faq-section">
                <div className="container">
                    <div className="text-center">
                        <h4 className="heading">Frequently asked questions</h4>
                        <p className="content mt-3">Everything you need to know about the product and billing.</p>
                    </div>
                    <FAQAccordion />
                </div>
            </section> */}
      <Footer />
    </div>
  );
}

export default Home;
