import React from "react";
import Header from "../component/Header";
import Footer from "../component/Footer";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "bootstrap-icons/font/bootstrap-icons.css";

function Imp() {
  return (
    <div className="main-layout">
      {" "}
      <Header />
      <div className="flex justify-center items-center min-h-screen p-4">
        <div className="container mx-auto max-w-4xl  p-6 rounded-lg ">
          <h5 className="text-xl font-bold mb-6 text-center">Disclaimer:</h5>
          <p
            className="text-black leading-relaxed"
            style={{
              textAlign: "justify",
              textJustify: "inter-word",
              hyphens: "auto",
            }}
          >
            Bizdateup Technologies Private Limited is neither a stock exchange
            nor do we intend to get recognized as a stock exchange under the
            Securities Contracts Regulation Act, 1956 (SCRA). We are not
            authorized by the capital markets regulator to solicit investments
            and we do not seek to advertise, solicit, invite, or induce any sort
            of investment from the users through this website. All trademarks
            and logos or registered trademarks and logos found on this site or
            mentioned herein belong to their respective owners and are solely
            being used for informational purposes. The information provided
            under this website should not be interpreted or construed as legal,
            financial, investment or any other form of advice. Information made
            available on the website shall not be considered as an offer and/or
            recommendation by us. Bizdateup technologies pvt ltd is not liable
            for financial or any other form of loss incurred by the user or any
            affiliated party on the basis of information provided herein. The
            securities traded on these platforms are not traded on any regulated
            exchange. Bizdateup also provides that it does not facilitate any
            online or offline buying, selling, or trading of securities. Read
            the entire offer related documents carefully. The investor is
            requested to take into consideration all the risk factors before the
            commencement of transaction, and the contents of this disclaimer are
            applicable to this document and any and all written or oral
            communication(s) made by Bizdateup or its directors, employees,
            associates, representatives and agents. We are not investment
            advisors or intermediaries in terms of the securities laws. We do
            not facilitate or direct any online or offline buying, selling, or
            trading of securities and the information presented on this website
            is for informational purposes only and is being provided by us only
            on your specific request, and any information obtained, or materials
            downloaded from this website is completely at your discretion. The
            content of this communication is confidential and privileged.
            Bizdateup does not guarantee or assure any return on investments and
            does not implicitly guarantee on completeness/accuracy of any
            information/data. Your investment is solely based on your judgement.
            We are not liable for any action taken by the user relying on
            material/ information provided under this website or for the
            consequences thereof. Investing in private markets and securities
            involves risks, and past performance does not guarantee future
            results. In case you require specific investment advice, you must
            seek the same independently from registered investment advisors.
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Imp;
