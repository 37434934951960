import React from "react";
import Header from "../component/Header";
import Footer from "../component/Footer";

function Terms() {
  return (
    <div className="main-layout">
      {" "}
      <Header />
      <div className="flex justify-center items-center min-h-screen p-4">
        <div className="container mx-auto max-w-4xl  p-6 rounded-lg ">
          <h5
            className="text-xl font-bold mb-6 text-center"
            style={{ textDecoration: "underline" }}
          >
            Disclaimer:
          </h5>
          <p
            className="text-black leading-relaxed"
            style={{
              textAlign: "justify",
              textJustify: "inter-word",
              hyphens: "auto",
            }}
          >
            The Terms of Use include the Privacy Policy, and Risks (“Terms of
            Use”) governing the use of the website of Bizdateup platform
            [https://www.bizdateup.com/] (“Website”) and the services
            (“Services”) provided on the Bizdateup platform. You
            ("Visitor/User/Member") express your acceptance of these Terms of
            Use and your agreement to abide by them by using the Website,
            including but not limited to accessing, visiting, or browsing the
            Website. The Terms of Use are a legally binding agreement between
            you, the Website's user, and us, the Website's owner. Please do not
            use this Website if you do not agree to our Terms of Service. The
            Terms of Use are to be read in conjunction with the terms and
            conditions of any agreement to which reference is made in that
            agreement. If the terms of such agreements conflict with the
            Provisions of Use, the terms of such agreements will prevail and
            govern so long as they pertain to topics expressly specified herein,
            and these Terms of Use will apply to all other matters.
            <h6
              className="text-xl underline  mx-auto font-bold  text-center"
              style={{ textDecoration: "underline" }}
            >
              Account Registration:
            </h6>
            You must register or establish an account in order to access some
            features of the Site or to submit anything on it ("Account"). You
            must finish the registration procedure by giving us accurate
            information. A password will also be chosen. Your password and
            account are your sole responsibility. You agree to promptly contact
            us if your Account is used without your permission or if there is
            any other security breach. You will not reveal your password, allow
            anyone else access to your Account, or do anything else that might
            compromise your Account's security. You will not transfer your
            Account to anybody without our explicit consent. You acknowledge and
            agree that your Account will be self-directed, and that you will be
            entirely responsible for any purchases, orders, investment choices,
            and instructions made in it. Despite the fact that the Site may
            include data, information, or material from third parties pertaining
            to investment strategies and/or opportunities to acquire and/or sell
            securities, you should not consider any of this content to be tax,
            legal, financial, or investment advice. You represent that any
            decision to invest is solely based on your consideration of the
            risks involving a particular security or those of a third-party
            (i.e. your personal financial advisor) is made at your own risk
            unless we provide you with specific advice that is clearly
            identified as an individualised recommendation specifically
            addressed to you (which is not expected to occur). You understand
            and agree that you are entirely responsible for assessing the
            suitability of an investment or strategy, and that you accept the
            risks associated with such decisions, which may include the risk of
            losing your whole capital. You agree and recognise that doing legal,
            accounting, financial, and other due diligence on the firms featured
            on the Site is exclusively your responsibility. Any legal, tax,
            financial, or investment advice should be sought from a licensed
            legal practitioner and an investment advisor. The Site will only
            give you the capacity to make your own financial decisions without
            giving you any advice.
            <h6
              className="text-xl underline  mx-auto font-bold  text-center"
              style={{ textDecoration: "underline" }}
            >
              {" "}
              Information about us and the Bizdateup platform{" "}
            </h6>
            Bizdateup Technologies Pvt Ltd ("we/us/our") owns and operates the
            Website. We were founded under the Companies Act, 2013 with the CIN
            U74999MH2021PTC353592 and our registered office is at G2, EMPIRE
            BUSINESS CENTRE, EMPIRE COMPLEX, 414, Senapati Bapat Marg, Lower
            Parel, near Shreeniwas Mill, Delisle Road, Mumbai - 400013. The
            Bizdateup platform allows early-stage initiatives to showcase their
            company ideas and conduct a fundraising campaign on the Website,
            making it easier for potential investors to determine whether or not
            to participate in such companies. The Website also offers
            early-stage businesses and potential investors with any other
            information or services that are required or ancillary to the
            primary activity mentioned below.
            <h6
              className="text-xl underline  mx-auto font-bold  text-center"
              style={{ textDecoration: "underline" }}
            >
              Access to the Website
            </h6>
            By using the Website, you agree to the Terms of Service and the
            Privacy Policy. Access to some areas of the Website is contingent on
            our approval of you as a "member," "issuing entity," or "issuer" on
            the Bizdateup Platform. We retain the right to withdraw or change
            the Services supplied to you through the Website at any time and
            without notice. We shall not be liable if our Website is unavailable
            to you for any reason at any time. Furthermore, we reserve the right
            to limit your access to all or part of the Website. All rights not
            expressly granted to you are reserved under these Terms of Service.
            <h6
              className="text-xl underline   mx-auto font-bold  text-center"
              style={{ textDecoration: "underline" }}
            >
              Registration and Membership{" "}
            </h6>
            If you want to explore the possibility of investing in issuer firms
            seeking capital through the Bizdateup Platform, you must first
            register as a member' on the Bizdateup platform. The conditions of
            registration, including eligibility requirements and rights as a
            member, are published on our website, and you may review them once
            you've joined the Bizdateup platform. If the Terms of Use and the
            unique membership terms for the Bizdateup Platform conflict, the
            specific membership conditions will take precedence. If you want to
            launch a fundraising campaign using the Bizdateup Platform, you must
            first register as a 'issuing firm' or an 'issuer' on the Bizdateup
            platform in order to use the full capabilities of the Website. Our
            Website contains the terms of registration, including qualifying
            requirements and rights after becoming a "issuing firm" or "issuer."
            If the Terms of Use and the specific conditions regulating your
            status as a 'issuer company' or 'issuer' on the Bizdateup Platform
            conflict, the specific conditions defining your status as a 'issuer
            company' or 'issuer' on the Bizdateup Platform will take precedence.
            <h6
              className="text-xl underline   mx-auto font-bold  text-center"
              style={{ textDecoration: "underline" }}
            >
              {" "}
              DISCLAIMER OF WARRANTIES, INACCURACIES OR ERRORS
            </h6>
            We will try to ensure that all information and recommendations,
            whether in relation to the Services, offerings or otherwise
            (hereinafter “Information”) provided as part of this Platform is
            correct at the time of inclusion on the Platform. We do not
            guarantee the accuracy of the Information. We make no
            representations or warranties as to the completeness or accuracy of
            the Information. You understand that we are neither a stock exchange
            nor do we intend to get recognized as a stock exchange under the
            Securities Contracts Regulation Act, 1956. We are not authorized by
            the capital markets regulator to solicit investments and we do not
            seek to advertise, solicit, invite, or induce any sort of investment
            from the users through this Platform. Information made available on
            the Platform shall not be considered as an offer and/or
            recommendation by us. We are not investment advisors or
            intermediaries in terms of the securities laws. We do not facilitate
            or direct any online or offline buying, selling, or trading of
            securities. The Information presented on this Platform is for
            informational purposes only and is being provided by us only on your
            specific request, and any information obtained, or materials
            downloaded from this Platform are completely at your discretion. The
            Information provided under this Platform should not be interpreted
            or construed as legal, financial, investment or any other form of
            advice. We are not liable for any action taken by the user relying
            on the material / information provided under this Platform or for
            the consequences thereof. Investing in private markets and
            securities involves risks, and past performance does not guarantee
            future results. In case you require specific investment advice, you
            must seek the same independently from registered investment
            advisors. You agree that Information is being supplied to you on the
            condition that you will make your own determination as to the
            suitability of such information for your purpose prior to use or in
            connection with the making of any decision. No Information on the
            Platform shall constitute an invitation to invest in us or any of
            our affiliates. Any use of the Platform or the Information is at
            your own risk. Neither we, our affiliates, nor their officers,
            employees or agents shall be liable for any loss, damage or expense
            arising out of any access to, use of, or reliance upon, this
            Platform, the Information, or any third-party website linked to the
            Platform. We are not responsible for the content of any third-party
            sites and do not make any representations regarding the content or
            accuracy of material on such sites. If you decide to access links of
            any third-party platforms, you are doing so entirely at your own
            risk and expense. As means to assist the users, we provide visual
            representations on the Platform including graphics, illustrations,
            photographs, images, videos, charts, screenshots, infographics and
            other visual aids. The Information could include inaccuracies or
            typographical errors. We have endeavoured to ensure that all the
            Information on the Platform is correct, but we neither warrant nor
            make any representations regarding the quality, accuracy or
            completeness of any data or information contained therein. We do not
            covenant or provide any representations and warranties:
            <br /> (a) in respect of deals, portfolio, investors, founders,
            quality, suitability, accuracy, reliability, performance, safety, or
            the content on Platform;
            <br /> (b) that the Services will be made available at all times;
            and
            <br /> (c) that the operation of Platform, including the functions
            contained in any content, information and materials on Platform or
            any third-party sites or services linked to Platform will be
            uninterrupted, or that the defects will be rectified, or that
            Platform or the servers that make such content, information and
            materials available are free of viruses or other harmful components.
            To the extent permitted by applicable law, We, our officers, agents,
            employees and directors, disclaim any liability against any loss,
            damage, expenses, liabilities, claim, injury caused due to the
            failure of performance, omission, defect, or deletion, interruption,
            error, delay, virus, communication, unauthorised access, theft,
            destruction, alteration or use of records on the Platform.
            <h6
              className="text-xl  underline mx-auto font-bold  text-center"
              style={{ textDecoration: "underline" }}
            >
              {" "}
              AVAILIBILITY AND ACCESIBILITY OF BIZDATEUP PLATFORM
            </h6>
            We control and operate this Platform from India and makes no
            representation that the Services available on the Platform are
            appropriate to be used or will be available for use in other
            locations outside India. If you use this Platform from outside
            India, you are entirely responsible for compliance with all
            applicable local laws. These Terms of Use do not constitute, nor may
            these Terms of Use be used for or in connection with any promotional
            activities or solicitation by anyone in any jurisdiction in which
            such promotional activities or solicitation are not authorized or to
            any person to whom it is unlawful to promote or solicit. The
            Services offered in one region may differ from those in other
            regions due to availability, local or regional laws, shipment and
            other considerations. We do not make any warranty or representation
            that a user in one region may obtain the Services from our site in
            another region and we may cancel a user's request or redirect a user
            to a separate site for that user’s region if a user attempts to
            order Services offered on a site in another region. Information that
            we publish on the world wide web may contain references or cross
            references to our programs and Services that are not announced or
            available in your country. Such references do not imply that we
            intend to announce such products, programs or Services in your
            country. Consult our local business contact for information
            regarding the programs and Services that may be available to you. We
            constantly monitor the user’s account in order to avoid fraudulent
            accounts and transactions. We reserve the right to initiate legal
            proceedings against such persons for fraudulent use of the Platform
            and any other unlawful acts or omissions in breach of these terms
            and conditions. In the event of detection of any fraudulent or
            declined transaction, prior to initiation of legal actions, we
            reserve the right to immediately delete such account and dishonour
            all past and pending requests without any liability. For the purpose
            of this clause, we shall owe no liability for any refunds.
            <h6 className="text-xl underline  mx-auto font-bold  text-center">
              {" "}
              THIRD PARTY INTERACTION AND LINKS TO THIRD PARTY SITES
            </h6>
            In your use of Platform, you may enter into correspondence with,
            avail services from or participate in promotions of advertisers or
            members or sponsors of Platform or access any other third-party
            website linked to the Platform. Unless otherwise stated, any such
            correspondence, advertisement, purchase or promotion, including the
            delivery of and the payment for services, and any other term,
            condition, warranty or representation associated with such
            correspondence, purchase or promotion, is solely between you and the
            applicable third party. You agree that we have no liability,
            obligation or responsibility for any such correspondence, purchase
            or promotion, access or usage of any third-party website and the
            contract under such instances remains between you and any such third
            party.
            <h6
              className="text-xl underline  mx-auto font-bold  text-center"
              style={{ textDecoration: "underline" }}
            >
              {" "}
              LICENSE AND USE OF YOUR CONTENT{" "}
            </h6>
            You grant to us a royalty-free, perpetual, irrevocable,
            non-exclusive right and license to adopt, publish, reproduce,
            disseminate, transmit, distribute, copy, use, create derivative
            works from, display worldwide, or act on any material posted by you
            on the Platform without additional approval or consideration in any
            form, media, or technology now known or later developed, for the
            full term of any rights that may exist in such content, and you
            waive any claim over all feedback, comments, ideas or suggestions or
            any other content provided through or on the Platform. You agree to
            perform all further acts necessary to perfect any of the above
            rights granted by you to us, including the execution of deeds and
            documents, at its request. You agree that we do not routinely
            monitor your postings on Platform but reserve the right to do so.
            However, if we become aware of inappropriate use of the Platform or
            any of its Services, we will respond in any way that, in its sole
            discretion, we deem appropriate. You acknowledge that we shall have
            the right to report to law enforcement authorities of any actions
            that may be considered illegal, as well as any information it
            receives of such illegal conduct. When requested, we will co-operate
            fully with law enforcement agencies in any investigation of alleged
            illegal activity on the internet. Submissions and unauthorised use
            of any materials contained on the Platform may violate copyright
            laws, trademark laws, the laws of privacy and publicity, certain
            communications statutes and regulations and other applicable laws
            and regulations. You alone are responsible for your actions or the
            actions of any person using your username and/or password. As such,
            you shall indemnify and hold us and our officers, directors,
            employees, affiliates, agents, licensors, and business partners
            harmless from and against any and all loss, costs, damages,
            liabilities, and expenses (including attorneys' fees) incurred in
            relation to, arising from, or for the purpose of avoiding, any claim
            or demand from a third party that your use of the Platform or the
            use of the Platform by any person using your user name and/or
            password (including without limitation your participation in the
            posting areas or your Submissions) violates any applicable law or
            regulation, or the rights of any third party. We reserve the right
            to terminate access to this Platform at any time and without notice.
            Further this limited license terminates automatically, without
            notice to you, if you breach any of these Terms. Upon termination,
            you must immediately destroy any downloaded and printed materials.
            Any provision of the Terms which imposes an obligation or creates a
            right that by its nature will be valid after termination or
            expiration of the Terms shall survive the termination or expiration
            of the Terms.
            <h6
              className="text-xl underline  mx-auto font-bold  text-center"
              style={{ textDecoration: "underline" }}
            >
              {" "}
              CONFIDENTIALITY
            </h6>
            You may obtain certain confidential information, including without
            limitation, technical, contractual, product, pricing, business
            related functions, activities and services, customer lists,
            knowledge of customer needs and preferences, business strategies,
            marketing strategies, methods of operation, markets and other
            valuable information that should reasonably be understood as
            confidential
            <b> (“Confidential Information”).</b> You acknowledge and agree to
            hold all Confidential Information in strict confidence. All title
            and interest in all the Confidential Information shall be vested in
            us. Upon termination of your account or use/ access of the Platform,
            you shall forthwith delete or remove any such Confidential
            Information. Your obligations regarding Confidential Information
            will survive the termination of these Terms.
            <h6
              className="text-xl underline  mx-auto font-bold  text-center"
              style={{ textDecoration: "underline" }}
            >
              {" "}
              USER WARRANTIES AND RESTRICTIONS
            </h6>
            You represent and warrant that: <br />
            (a) your use of Platform and/or Services will not violate any
            applicable law or regulation; <br />
            (b) all information that is submitted to us by you in connection
            with Platform and/or Services is true, accurate and lawful; <br />
            (c) use of the content and material supplied by you does not breach
            any applicable law/agreement and will not cause injury to any person
            or entity (including that the content or material is not
            defamatory). If at any time, the information provided by you is
            found to be false or inaccurate, we shall have the right to reject
            registration and restrict you from using the Services and other
            affiliated services in the future without any prior intimation
            whatsoever. You agree to indemnify us and our affiliates for all
            claims brought by a third party against us or any of our affiliates
            arising out of or in connection with a breach of any of these
            warranties. You will use Platform for lawful purposes only and will
            not undertake any activity that is harmful to Platform or its
            content or otherwise not envisaged through the Platform. You have a
            limited license to access and use the Platform, solely for the
            purpose of availing the Services, subject to these Terms. You shall
            not do any of the following:
            <br /> (a) delete or modify any content on Platform, such as any
            information regarding the Services; <br />
            (b) use any engine, software, tool, agent or other mechanism (such
            as spiders, robots, avatars, worms, time bombs etc.) to navigate or
            search Platform;
            <br /> (c) make false or malicious statements against the Services,
            Platform or us; <br />
            (d) post, copy, submit, upload, distribute, or otherwise transmit or
            make available any software or other computer files that contain a
            virus or other harmful component, or otherwise disrupt or damage
            Platform and/or Services or any connected network, or otherwise
            interfere with any person or entity’s use or enjoyment of Platform
            and/or the Services; <br />
            (e) introduce any trojans, viruses, any other malicious software,
            any bots or scrape Platform for any information;
            <br /> (f) probe, scan, or test the vulnerability of any system,
            security or authentication measures implemented by us or otherwise
            tamper or attempt to tamper with our technological design and
            architecture;
            <br /> (g) hack into or introduce malicious software of any kind
            onto Platform;
            <br /> (h) gain unauthorized access to, or interfere with, or
            damage, or disrupt the server on which the details connected to the
            Services are stored, or any other server, computer, or database
            connected to the Services; or <br />
            (i) engage in any form of antisocial, disruptive, or destructive
            acts, including “flaming,” “spamming,” “flooding,” “trolling,” and
            “phishing” as those terms are commonly understood and used on the
            internet. You are prohibited from hosting, displaying, uploading,
            modifying, publishing, transmitting, updating or sharing on or
            through Platform, any information that:
            <br /> (a) belongs to another person and to which you do not have
            any right; <br />
            (b) is harmful, harassing, blasphemous, defamatory, obscene,
            pornographic, paedophilic, invasive of another’s privacy, hateful,
            relating to or encouraging money laundering or gambling, or
            otherwise harmful in any manner whatsoever;
            <br /> (c) harms minors in any way; <br />
            (d) infringes any patent, trademark, copyright or other proprietary
            rights;
            <br /> (e) violates any law for the time being in force;
            <br /> (f) deceives or misleads the addressee about the origin of
            such messages or communicates any information which is grossly
            offensive or menacing in nature; <br />
            (g) impersonates or defames another person; or
            <br /> (h) contains software viruses or any other computer code,
            files or programs designed to interrupt, destroy or limit the
            functionality of any computer resource.
            <h6
              className="text-xl underline  mx-auto font-bold  text-center"
              style={{ textDecoration: "underline" }}
            >
              {" "}
              INDEMNIFICATION AND LIMITATION OF LIABILITY
            </h6>
            You agree to indemnify, defend and hold harmless us, our affiliates,
            officers, directors, employees, consultants, licensors, agents, and
            representatives from and against any and all third- party claims,
            losses, liabilities, damages, and/or costs (including reasonable
            attorney fees and costs) arising from your access to or use of
            Platform or the Services, violation of these Terms or infringement
            of any of our or any third-party intellectual property or other
            rights. We will notify you promptly of any such claim, loss,
            liability, or demand, and in addition to the foregoing, you agree to
            provide We with reasonable assistance, at their expense, in
            defending any such claim, loss, liability, damage, or cost To the
            fullest extent permitted by law, we are not and will not be liable
            for any direct, indirect, remote, punitive, special, incidental,
            consequential, or exemplary damages (including, without limitation,
            loss of business, revenue, profits, goodwill) arising out of or in
            connection with the Platform or the Services, even if we have
            previously been advised of, or reasonably could have foreseen, the
            possibility of such damages, however they arise, whether in breach
            of contract or in tort (including negligence), including without
            limitation to damages due to: <br />
            (i) the use of or the inability to use the Platform;
            <br /> (ii) conduct of any user on the Platform;
            <br /> (iii) unauthorized access to or alteration of transmissions
            or data; <br />
            (iv) malicious or criminal behaviour, or false or fraudulent
            transactions; or <br />
            (v) technical or other operational lapses on the Platform. In any
            event, our liability arising out of the Terms or use of the Platform
            shall not exceed INR [●insert amount].
            <h6
              className="text-xl underline  mx-auto font-bold  text-center"
              style={{ textDecoration: "underline" }}
            >
              {" "}
              Applicable Law and Jurisdiction
            </h6>
            These Terms are governed by and to be interpreted in accordance with
            laws of India, without regard to the choice or conflicts of law
            provisions of any jurisdiction. You agree, in the event of any
            dispute arising in relation to these Terms or any dispute arising in
            relation to the Platform whether in contract or tort or otherwise,
            to submit to the jurisdiction of the courts located at [Mumbai,
            India], for the resolution of all such disputes.
            <h6
              className="text-xl underline  mx-auto font-bold  text-center"
              style={{ textDecoration: "underline" }}
            >
              {" "}
              FORCE MAJEURE
            </h6>
            We will not be held responsible for any delay or failure to comply
            with its obligations if the delay or failure arises from any cause
            which is beyond our reasonable control.
            <h6
              className="text-xl underline  mx-auto font-bold  text-center"
              style={{ textDecoration: "underline" }}
            >
              {" "}
              WAIVER
            </h6>
            No provision in these Terms shall be deemed waived and no breach
            excused, unless such waiver or consent is in writing and signed by
            us. Any consent by us to, or waiver of your breach, whether
            expressed or implied, shall not constitute consent to, waiver of, or
            excuse for any other different or subsequent breach.
            <h6
              className="text-xl underline  mx-auto font-bold  text-center"
              style={{ textDecoration: "underline" }}
            >
              {" "}
              SEVERABILITY
            </h6>
            If any provision of these Terms is held by a court of competent
            jurisdiction to be unenforceable under applicable law, then such
            provision will be excluded from these Terms and the remainder of
            these Terms will be interpreted as if such provision were so
            excluded and will be enforceable in accordance with its terms;
            provided however that, in such event, these Terms will be
            interpreted so as to give effect, to the greatest extent consistent
            with and permitted by applicable law, to the meaning and intention
            of the excluded provision as determined by such court of competent
            jurisdiction.
            <h6
              className="text-xl underline  mx-auto font-bold  text-center"
              style={{ textDecoration: "underline" }}
            >
              {" "}
              AMENDMENT
            </h6>
            These Terms are subject to amendments and modifications and may be
            updated from time to time, without any advance notice. You are
            requested to regularly review the Terms as available on Platform.
            Your relationship with the Platform will be governed by the most
            current version of these Terms, as published on Platform. These
            Terms supersede all previous oral and written terms and conditions
            (if any) communicated to you by us, for the use of Platform, and the
            rights and liabilities with respect to any Services to be provided
            by us shall be limited to the scope of these Terms.
            <h6
              className="text-xl underline  mx-auto font-bold  text-center"
              style={{ textDecoration: "underline" }}
            >
              {" "}
              Miscellaneous
            </h6>
            There is no collaboration or agency. The Terms of Service are not
            intended to constitute a partnership or joint venture between you
            and us. Nothing in the Terms of Service shall be interpreted to make
            you and us agents of one another. Specific Capabilities Each of us
            agrees that monetary damages may not be an adequate remedy and that
            either of us is entitled to an injunction, restraining order, right
            of recovery, suit for specific performance, or such other equitable
            relief as a court of competent jurisdiction may deem necessary or
            appropriate to prevent the other from violating the Terms of Use or
            to enforce the performance of the covenants, representations, and
            obligations contained in the Terms of Use. These injunctive remedies
            are cumulative and in addition to any other rights and remedies the
            Parties may have at law or in justice, including a claim to damages.
            Severability In the event that any duty or obligations are or become
            unenforceable in whole or in part, each obligation under the Terms
            of Use shall be considered as a distinct obligation and shall be
            separately enforceable as such. If any term or provisions of the
            Terms of Use are found to be unenforceable, both of us agree to
            alter such clauses as may be required to make the provision lawful
            and effective. Regardless of the foregoing, any provision that
            cannot be amended to make it valid and effective shall be deemed
            deleted from the Terms of Use, and any such deletion shall not
            affect the enforceability of the remainder of the Terms of Use not
            so deleted, provided that the fundamental terms of the Terms of Use
            are not altered. Non-Exclusive Treatments The rights and remedies
            set out herein are cumulative, and none are exclusive of any other
            rights or remedies that any of us may have at law or in equity. The
            rights and remedies of any of us based on, arising from, or
            otherwise in respect of any inaccuracy or breach of any
            representation, warranty, covenant, or agreement or failure to
            fulfil any condition shall not be limited in any way by the fact
            that the act, omission, occurrence, or another state of facts upon
            which any such inaccuracy or breach is based may also be the subject
            matter of any other representation, warranty, covenant, or agreement
            as to which there is no inaccuracy or breach. Invalidity in Part If
            any provision of the Terms of Use or its application to any person
            or circumstance is held invalid or unenforceable to any extent for
            any reason, including by reason of any law, regulation, or
            government policy, the remainder of the Terms of Use and the
            application of such provision to persons or circumstances other than
            those as to which it is held invalid or unenforceable shall not be
            affected, and each provision of the Terms of Use shall be valid and
            enforceable. Any provision of the Terms of Use that is invalid or
            unenforceable must be replaced with a provision that is valid and
            enforceable and most closely represents the original purpose of the
            invalid and unenforceable provision. Third-Party Legal Rights
            Nothing in the Terms of Use is intended or inferred to confer or
            provide any person, other than us, any rights or remedies under or
            by reason of the Terms and Conditions or any transaction envisaged
            by the Terms of Use. Time is of the essence Time is of the essence
            in all situations arising from or related to the Terms of Use.
            Execution When you visit the Website, the Terms of Use are regarded
            properly executed and become effective and binding on you and us.
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Terms;
