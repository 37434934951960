import React from "react";
import Header from "../component/Header";
import Footer from "../component/Footer";

function Policy() {
  return (
    <div className="main-layout">
      {" "}
      <Header />
      <div className="flex justify-center items-center min-h-screen p-4">
        <div className="container mx-auto max-w-4xl  p-6 rounded-lg ">
          <h5
            className="text-xl font-bold mb-6 text-center"
            style={{ textDecoration: "underline" }}
          >
            Disclaimer:
          </h5>
          <p
            className="text-black leading-relaxed"
            style={{
              textAlign: "justify",
              textJustify: "inter-word",
              hyphens: "auto",
            }}
          >
            {" "}
            WE BIZDATEUP TECHNOLOGIES PRIVATE LIMITED is a company incorporated
            under the provisions of Companies Act, 2013, having CIN
            U72900MH2022379408, and its registered address at G2, Empire
            Business Centre, Empire Complex, 414 Senapati Bapat Marg, Delisle
            Rd, near Shreeniwas Mill, Lower Parel, Mumbai, Maharashtra, 400013
            website [https://www.bizdateup.com/] It has been officially
            formulated in accordance with the Information Technology Act of 2000
            ("Act"), and the Rules enacted are designed to guarantee that the
            information submitted by its users ("you") is protected to the
            greatest extent possible. By using the Website, you agree to the
            terms and circumstances set out in this Privacy Policy regarding the
            collection, retention, and use of information. You may quit and stop
            using the Website if you do not agree to the Privacy Policy. The
            terms and conditions of the Bizdateup platform are incorporated in
            the Privacy Policy. If the terms of such agreements and the Privacy
            Policy conflict, the provisions of such agreements shall prevail and
            govern so long as they pertain to topics expressly specified herein,
            and this Privacy Policy will apply to all other subjects. Our
            members share their professional identities, communicate with their
            network, exchange information and professional insights, post and
            watch relevant content, learn, and find business and career
            possibilities. Non-members ("Visitors") can view the content and
            data on some of our Services.
            <h6
              className="text-xl underline  mx-auto font-bold  text-center"
              style={{ textDecoration: "underline" }}
            >
              1. General:
            </h6>
            This Policy, together with the terms of use (available here) govern
            your use of our Platform and/ or Services; describes the types of
            information we may collect from you or that you may provide to us
            when you use our Platform and/ or Services and our associated
            services, and lays down our practices and purposes for collecting,
            receiving, processing, storing, dealing, using, maintaining,
            transferring, and disclosing such information. This Policy applies
            to information we collect or receive:
            <br /> (a) On our Platform;
            <br /> (b) Via e-mail and other electronic communication between you
            and us; or
            <br /> (c) When you use the Services;
            <br />
            <br /> This Policy does not apply to information collected by:{" "}
            <br /> <br /> (a) us in physical form and not in a computer
            resource; or
            <br /> (b) any third party, including through any website,
            application or content (including advertising) that may link to or
            be accessible from our Platform. Please read this Policy carefully
            to understand our policies and practices regarding your information
            collected, stored and handled by us and how we will treat it. By
            accessing our Platform, and/or by using the Services, you agree to
            and accept the practices described in this Policy. If you do not
            agree with the terms of this Policy, please do not access the
            Platform and/or Services. You agree that the Information (defined
            below) provided to us by you has been received or collected by
            lawful means with your consent and does not constitute a violation
            of data protection laws of India. You agree and acknowledge that you
            shall be solely liable if you violate any provision or term of this
            Policy. We, our employees, agents, directors, partners and officers
            will not be liable for any loss or direct or indirect damage arising
            from your failure to comply with this Policy. You agree and
            understand that you may be held liable for any losses incurred to us
            or our business partners or our customers or any other individual
            due to your violation of this Policy or any unauthorized use of the
            Platform and/ or Services by you. We reserve the right to refuse
            access to the Platform and/ or Services, remove or edit content at
            any time without notice to you if you violate the terms of this
            Policy.
            <br />
            <h5
              className="text-xl underline  mx-auto font-bold  text-center"
              style={{ textDecoration: "underline" }}
            >
              1. Access of Platform to Minors
            </h5>
            The Platform and/or Services are not directed at or intended for
            minors and children under the age of 18 (eighteen) years and / or
            persons with disability. By accessing the Website, you confirm that
            you are 18 (eighteen) years of age or above and are fully competent
            to understand this Policy and abide by the terms of this Policy. We
            do not knowingly collect any Personal Information from minors or
            persons with disability. In case you are below 18 (eighteen) years
            of age or a person with disability, you may access and use the
            Platform only if enabled by a parent or legal guardian.
            <h5
              className="text-xl underline  mx-auto font-bold  text-center"
              style={{ textDecoration: "underline" }}
            >
              1. Information We Collect and Store About You
            </h5>
            We may collect or receive Personal Information (defined below) and
            sensitive personal data or information <b>(“SPDI”)</b> (Personal
            Information and SPDI are collectively referred as,{" "}
            <b> “Information”</b>) voluntarily given by you with your consent
            when you access the Platform and/or use Services. For the purposes
            of this Policy,<b> “Personal Information”</b> means the information
            (whether individually or in combination with other information
            available or likely to be available with us) that identifies,
            relates to, describes, is reasonably capable of being associated
            with, or could reasonably be linked, directly or indirectly, to a
            natural person. We may combine non-personal information with
            Personal Information, and in those circumstances, we will treat the
            combined information as Personal Information. We may collect or
            receive several types of Personal Information from and about you
            when you use our Platform or Services including the following
            information:
            <br /> (a) First and last name,
            <br /> (b) Postal address, <br /> (c) Email address,
            <br /> (d) Phone number, <br /> (e) Gender,
            <br /> (f) Nationality, <br /> (g) Internet/network information,
            such as device name, device ID, browsing history, browser type,
            interaction with Platform, or
            <br /> (h) Inquiry information, such as your questions/feedback to
            us or survey responses.
            <h5
              className="text-xl underline  mx-auto font-bold  text-center"
              style={{ textDecoration: "underline" }}
            >
              1. How We Use Your Information
            </h5>
            We use the Information that we collect about you or that you provide
            to us in the following manner: <br /> (a) To provide you with access
            to Services and our Platform. <br /> (b) To provide you with
            information, products or services that you request from us from time
            to time.
            <br /> (c) To notify you about changes to our Platform, or Services,
            or any additional services that we offer or provide though it.{" "}
            <br /> (d) To improve your experience. <br /> (e) For internal
            record-keeping and analytical purposes including data analysis,
            auditing and research.
            <br /> (f) To detect, monitor, investigate, deter, and protect
            against fraudulent, unauthorized or illegal activity.
            <br /> (g) To inform you about promotions, events, updates, offers
            and news related to the Platform or Services.
            <br /> (h) To fulfil any other purpose for which you provide your
            Information.
            <br /> (i) To comply with any legal or regulatory requirement or
            comply with a request from any governmental or judicial authority.
            <h5
              className="text-xl underline  mx-auto font-bold  text-center"
              style={{ textDecoration: "underline" }}
            >
              1. How We Share Information/ Disclosure of Your Information
            </h5>
            How We Share Information/ Disclosure of Your Information We may from
            time to time, disclose Information about you in anonymous form,
            non-personal information or information that does not identify you
            or any individual with any third party, service providers, our
            affiliates for, inter alia, commercial, research, statistical
            analysis and business intelligence purposes. You agree and
            understand that such anonymous data is owned by us and we may, in
            our sole and absolute discretion, sell or otherwise transfer such
            research, statistical or intelligence data in an anonymous or
            non-personally identifiable form to third parties and affiliates
            which may include our advertisers, sponsors, investors, strategic
            partners, in order to help grow our business.
            <br /> We shares and discloses Personal Information to other parties
            as needed to provide our Services and operate our business. The
            categories of other parties with Whom we may share information
            include:
            <br /> The Bizdateup Family of Companies - Affiliates and
            Subsidiaries. Bizdateup may share Personal Information about you
            with affiliates and subsidiaries.
            <br /> Service Providers: We may share the information that you have
            provided us with certain third party services, including payment
            processors, web services providers, developers, security and storage
            service providers, analytics service providers, and providers used
            to analyze our websites’ and applications’ functionalities.
            <br /> Business Services: We may share any category of Personal
            Information with our business services providers in the operation of
            our business and facilitation of the Services, including agents,
            auditors, financial institutions, and professional advisors.
            <br /> Corporate Activities: We may share any category of Personal
            Information with other companies or entities as part of any
            reorganization, merger, sale, joint venture, assignment, transfer,
            or other disposition of all or any portion of our business, assets,
            or stock. In such case your Personal Information may be transferred
            to the potential or actual acquirer, successor or assignee.
            <br /> Government and Legal: We may share any category of Personal
            Information with other parties, including public authorities, as may
            be required by applicable law, regulation, or legal process.
            <br /> Change in Control or Sale: We may share your information if
            our company is sold, but it must be used in compliance with this
            Privacy Policy. We may also share your personal information in the
            course of a sale, merger, or change in ownership, or in anticipation
            of any of these occurrences. Unless you agree otherwise, any
            organisation that purchases us or a portion of our business will
            have the right to continue to use your data in the manner described
            in this Privacy Policy.
            <br /> By using, accessing or browsing our Platform and/or Services,
            you consent to the disclosure of your Information by us, in
            accordance with the terms of this Policy. We may disclose
            Information that we collect, receive or you provide as described in
            this Policy in the following manner: <br /> (a) To our employees on
            a need-to-know basis.
            <br /> (b) To our subsidiaries and affiliates on a need-to-know
            basis.
            <br /> (c) To our contractors, service providers and other third
            parties, such as our payment processing partners who we use to
            support our Platform and/or Services. We ensure that such third
            parties and service providers bound by contractual obligations to
            keep your Information confidential and use it only for the purposes
            for which we disclose it to them.
            <br /> (d) To a buyer or successor in the event of a merger,
            divestiture, restructuring, reorganization, dissolution or other
            sale or transfer of some or all of our assets, whether as a going
            concern or as part of bankruptcy, liquidation or similar proceeding,
            in which information held by us about the Platform and/or Services,
            including Information of our customers and authorized users are
            among the assets transferred.
            <br /> (e) For any other purpose disclosed by us when you provide
            the Information.
            <br /> (f) To comply with any court order, law or legal process,
            including responding to any government or regulatory request or
            authorized agency. <br /> (g) To enforce or apply other agreements,
            including for billing and collection purposes. <br /> (h) If we
            believe disclosure is necessary or appropriate to protect our
            rights, property, or safety, or the rights, property, or safety of
            our customers or any third party.
            <h5
              className="text-xl underline  mx-auto font-bold  text-center"
              style={{ textDecoration: "underline" }}
            >
              1. Data Retention and Disposal
            </h5>
            Bizdateup retains Personal Information in accordance with its data
            retention polices consistent with applicable law. We will retain
            your personal data only for as long as necessary for the purposes it
            was retained, such as to enable you to use the Website and your
            products or to provide services to you. In some instances, we may
            retain data for longer periods in order to comply with applicable
            laws (including those regarding document retention), resolve
            disputes with any parties, and otherwise as necessary to allow us to
            conduct our business.
            <h5
              className="text-xl underline  mx-auto font-bold  text-center"
              style={{ textDecoration: "underline" }}
            >
              1. Data Processing and Security
            </h5>
            Security of your Information is of utmost importance for us. We have
            adopted security measures that are in line with the industry
            standards and applicable law to protect the Information that you
            provide to us, and to prevent unauthorized access, public
            disclosure, use, modification, damage or loss such Information. We
            take all reasonable and practical steps to protect your Information
            and actively pass relevant security and privacy protection
            certifications. The safety and security of your Information also
            depends on you. In the event we are made aware of any unauthorised
            or illegal use of the Platform and/or Services or breach of this
            Policy, you shall fully cooperate with us to investigate and remedy
            such use or breach, as applicable. Further, in the event, there is a
            breach or unauthorized, illegal or accidental use or access to your
            Information in our possession, we shall promptly intimate you of
            such breach and take necessary measures to address the issue as
            required by Applicable Laws. Although we make best possible efforts
            to transmit and store all the Information provided by you by
            encrypting data in transit or in a secure operating environment that
            is not open to public, you understand and acknowledge that there is
            no such thing as complete security and we do not guarantee that
            there will be no unintended disclosures of any information and
            potential security breaches. You agree not to hold us responsible
            for any breach of security or for any action of any third parties
            that receive your Information or events that are beyond our
            reasonable control including, acts of government, computer hacking,
            unauthorized access to computer data and storage device, computer
            crashes, breach of security and encryption, etc. Any transmission of
            Information is at your own risk. We are not responsible for
            circumvention of any privacy settings or security measures contained
            on our Platform or Services.
            <h5
              className="text-xl underline  mx-auto font-bold  text-center"
              style={{ textDecoration: "underline" }}
            >
              1. Third Party Links on Our Platform
            </h5>
            The Platform and/or Services may contain links to other third-party
            websites for your convenience. You agree and understand that we do
            not exercise control over third party websites displayed as search
            results or links on the Platform and/or Services. These other sites
            may place their own cookies or other files on your computer, collect
            data or solicit Information from you, on which we have no control
            and shall not be held responsible or liable. We do not make any
            representations concerning the privacy practices or policies of such
            third parties or terms of use of such websites, nor do we guarantee
            the accuracy, integrity, or quality of the information, data, text,
            software, sound, photographs, graphics, videos, messages or other
            materials available on such websites. You agree and understand the
            inclusion or exclusion of such third-party links does not imply any
            endorsement by us of such websites, the websites’ provider, or the
            information on the website. Once you leave the Platform and/or
            Services or are redirected to a third-party website, application, or
            other online service, we encourage you to read the privacy policy
            applicable to that third party.
            <h5
              className="text-xl underline  mx-auto font-bold  text-center"
              style={{ textDecoration: "underline" }}
            >
              1. Cookies and Other Identifiers
            </h5>
            We use cookies throughout our websites and web applications to track
            user interactions, personalize your user experience, and promote our
            Services. Cookies are small files stored on a computer that are
            designed to hold small amounts of data specific to a user and the
            websites or applications to help tailor that user’s experience.
            <h5
              className="text-xl underline  mx-auto font-bold  text-center"
              style={{ textDecoration: "underline" }}
            >
              1. Grievance Redressal Mechanism
            </h5>
            The Website shall appoint a grievance redressal officer ("Grievance
            Officer") to address any of your grievances or inaccuracies in the
            information published on the Website. As specified by the Act, the
            Grievance Officer must resolve all grievances within one month of
            receipt. If you have any queries or complaints about our Policy,
            please contact Bizdateup first. You can also write to us at
            Bizdateup G2, EMPIRE BUSINESS CENTRE, EMPIRE COMPLEX, 414 Senapati
            Bapat Marg, Lower Parel, near Shreeniwas Mill Delisle Road, Mumbai
            -400013.
            <h5
              className="text-xl underline  mx-auto font-bold  text-center"
              style={{ textDecoration: "underline" }}
            >
              1. Subject to Change
            </h5>
            This Privacy Policy is subject to change and may be updated or
            revised from time to time, without any prior notification to the
            User, at the sole discretion of Bizdateup. It is the responsibility
            of the User to periodically review the Privacy Policy to determine
            whether there have been any revisions or updates. The last updated
            date of the Policy has been mentioned above for the reference of the
            User.
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Policy;
